import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator';
import { MetaPropertyCharset, MetaPropertyEquiv, MetaPropertyName, MetaPropertyMicrodata, MetaPropertyProperty } from 'vue-meta';
import { PageData } from '~/models/Page';
import { buildPageHeadMetaInfo } from '~/helpers/buildPageHead';

interface PageState {
    // Поля стейта указываем с нижним подчёркиванием, чтобы названия не пересекались с экшенами и геттерами
    // Так же это сигнализирует что эти поля не являются публичными
    _data: PageData | null
    _raw: PageData | null
    _host: string | null
}

@Module({
    // Имя стора должно совпадать с именем файла
    name: 'page',

    // Эти два параметра обязательны. Без них работать не будет.
    stateFactory: true,
    namespaced: true
})
export default class PageStore extends VuexModule {
    private _data: PageState['_data'] = null;
    private _raw: PageState['_raw'] = null;
    private _host: PageState['_host'] = null;

    @VuexMutation
    // Мутиации тоже указываем с `_`, чтобы не пересекалось с экшенами и геттерами
    // Не забываем указывать что это приватное поле `private`, чтобы извне не было возможности поменять данные
    private _update(payload: PageState['_raw']): void {
        this._raw = payload;
    }

    @VuexMutation
    private _reset(): void {
        this._raw = null;
    }

    @VuexMutation
    private _fix(): void {
        this._data = this._raw;
    }

    @VuexMutation
    private _setHost(payload: PageState['_host']): void {
        this._host = payload;
    }

    @VuexAction
    update(payload: PageState['_raw']): void {
        this._update(payload);
    }

    @VuexAction
    reset(): void {
        this._reset();
    }

    @VuexAction
    fix(): void {
        this._fix();
    }

    @VuexAction
    setHost(payload: PageState['_host']): void {
        this._setHost(payload);
    }

    get data(): PageState['_data'] {
        return this._data;
    }

    get title(): string {
        if (this._data) {
            return this._data.title;
        }

        return '';
    }

    get metaTitle(): string {
        if (this._data) {
            return this._data.meta?.title || this._data.title;
        }

        return '';
    }

    get metaDescription(): string {
        if (this._data) {
            return this._data.meta?.description || '';
        }

        return '';
    }

    get metaInfo(): (MetaPropertyCharset | MetaPropertyEquiv | MetaPropertyName | MetaPropertyMicrodata | MetaPropertyProperty)[] {
        if (!this._data) {
            return [];
        }

        return buildPageHeadMetaInfo(this._data, this._host);
    }

    get pageHost(): string {
        return this._host || '';
    }

    get seoTitle(): string {
        return this._data?.seoTitle || '';
    }

    get seoTag(): string {
        return this._data?.seoTag || '';
    }

    get seoContent(): string {
        return this._data?.seoContent || '';
    }
}
