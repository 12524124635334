import Vue from 'vue';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options = {
    // You can set your default options here
    transition: 'slide-down-fade',
    timeout: 2000,
    icon: false,
    closeOnClick: true,
    hideProgressBar: true,
    closeButton: false,
    position: 'top-center',
    containerClassName: 'center',
    shareAppContext: true
};


Vue.use(Toast, options);
