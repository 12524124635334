//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from '~/components/TheHeader.vue';
import TheFooter from '~/components/TheFooter';
import { layoutStore, commonStore } from '~/store';
import { LAYER_ADD_VENDOR_REVIEW, LAYER_NEWS_CHANNEL, LAYER_NEWS_DETAIL } from '@/models/Layer';
import CookieAlert, { DISCLAIMER_COOKIE_KEY } from '@/components/CookieAlert';
import { allMetrics } from '~/mixins/metricsMixin';
import ChatCarrotQuest from '~/components/ChatCarrotQuest';
import documentSizeMixin from '@/mixins/documentSizeMixin';

export default {
    components: { CookieAlert, TheFooter, TheHeader, ChatCarrotQuest },
    mixins: [documentSizeMixin],
    head() {
        return {
            htmlAttrs: {
                class: [this.activeTheme]
            },
            ...allMetrics()
        };
    },
    computed: {
        headerTheme() {
            return layoutStore.headerTheme;
        },
        headerMenu() {
            return commonStore.headerMenu || [];
        },
        footerMenu() {
            return commonStore.footerMenu || [];
        },
        isCookieDisclaimerShown() {
            return this.$cookies.get(DISCLAIMER_COOKIE_KEY);
        },
        isChatInit() {
            return commonStore.isChatInit;
        },
        activeTheme() {
            return layoutStore.activeTheme;
        },
        isCookieAccepted() {
            return commonStore.isCookieAccepted;
        },
        showChat() {
            return (this.documentSize.width > 500 && !this.$device.isMobile) || this.isCookieAccepted;
        }
    },
    mounted() {
        commonStore.updateIsCookieAccepted(this.$cookies.get(DISCLAIMER_COOKIE_KEY) === 1);
        this.listenNewsDetailLayer();
        this.listenNewsChannelLayer();
        this.listenVendorAddReview();
    },
    methods: {
        listenNewsDetailLayer() {
            const id = this.$route.query[LAYER_NEWS_DETAIL];
            if (id) {
                setTimeout(() => {
                    this.$layer.open('NewsCardDetailLayer', {
                        id
                    });
                }, 100);
            }
        },
        listenNewsChannelLayer() {
            if (this.$route.query[LAYER_NEWS_CHANNEL]) {
                setTimeout(() => {
                    this.$layer.open('NewsChannelLayer');
                }, 100);
            }
        },
        listenVendorAddReview() {
            const vendorId = this.$route.query[LAYER_ADD_VENDOR_REVIEW];
            if (vendorId) {
                setTimeout(() => {
                    this.$layer.open('AddReviewLayer', {
                        vendorId
                    });
                }, 100);
            }
        }
    }
};
