import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _053e915f = () => interopDefault(import('../pages/application-review.vue' /* webpackChunkName: "pages/application-review" */))
const _93634d54 = () => interopDefault(import('../pages/application.vue' /* webpackChunkName: "pages/application" */))
const _36a112c8 = () => interopDefault(import('../pages/application/finish.vue' /* webpackChunkName: "pages/application/finish" */))
const _3a91417b = () => interopDefault(import('../pages/application/five.vue' /* webpackChunkName: "pages/application/five" */))
const _76f8fdef = () => interopDefault(import('../pages/application/four.vue' /* webpackChunkName: "pages/application/four" */))
const _ffe6f9ae = () => interopDefault(import('../pages/application/six.vue' /* webpackChunkName: "pages/application/six" */))
const _0a7d83b6 = () => interopDefault(import('../pages/application/three.vue' /* webpackChunkName: "pages/application/three" */))
const _675078da = () => interopDefault(import('../pages/application/two.vue' /* webpackChunkName: "pages/application/two" */))
const _278c0759 = () => interopDefault(import('../pages/application/index.vue' /* webpackChunkName: "pages/application/index" */))
const _e828e2a6 = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _09b88c56 = () => interopDefault(import('../pages/external-frame.vue' /* webpackChunkName: "pages/external-frame" */))
const _605a584a = () => interopDefault(import('../pages/offers-old.vue' /* webpackChunkName: "pages/offers-old" */))
const _722dca81 = () => interopDefault(import('../pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _1c176355 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _296ff1f7 = () => interopDefault(import('../pages/articles/_.vue' /* webpackChunkName: "pages/articles/_" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/application-review/",
    component: _053e915f,
    pathToRegexpOptions: {"strict":true},
    name: "application-review"
  }, {
    path: "/application/",
    component: _93634d54,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "finish/",
      component: _36a112c8,
      pathToRegexpOptions: {"strict":true},
      name: "application-finish"
    }, {
      path: "five/",
      component: _3a91417b,
      pathToRegexpOptions: {"strict":true},
      name: "application-five"
    }, {
      path: "four/",
      component: _76f8fdef,
      pathToRegexpOptions: {"strict":true},
      name: "application-four"
    }, {
      path: "six/",
      component: _ffe6f9ae,
      pathToRegexpOptions: {"strict":true},
      name: "application-six"
    }, {
      path: "three/",
      component: _0a7d83b6,
      pathToRegexpOptions: {"strict":true},
      name: "application-three"
    }, {
      path: "two/",
      component: _675078da,
      pathToRegexpOptions: {"strict":true},
      name: "application-two"
    }, {
      path: "",
      component: _278c0759,
      pathToRegexpOptions: {"strict":true},
      name: "application"
    }]
  }, {
    path: "/business/",
    component: _e828e2a6,
    pathToRegexpOptions: {"strict":true},
    name: "business"
  }, {
    path: "/external-frame/",
    component: _09b88c56,
    pathToRegexpOptions: {"strict":true},
    name: "external-frame"
  }, {
    path: "/offers-old/",
    component: _605a584a,
    pathToRegexpOptions: {"strict":true},
    name: "offers-old"
  }, {
    path: "/offers/",
    component: _722dca81,
    pathToRegexpOptions: {"strict":true},
    name: "offers"
  }, {
    path: "/search/",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/support/",
    component: _1c176355,
    pathToRegexpOptions: {"strict":true},
    name: "support"
  }, {
    path: "/articles/*",
    component: _296ff1f7,
    pathToRegexpOptions: {"strict":true},
    name: "articles-all"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/*",
    component: _2efaf336,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
