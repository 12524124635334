import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';
import { GetAllCitiesRequestQuery, AllCitiesList, CityDTO } from '~/models/Cities';


export default class CityService {
    /**
     * Получить список предустановленных городов
     * @param config Настройки запроса
     */
    static fetchPredefinedCities(config?: RequestConfig): Promise<Array<CityDTO>> {
        return api.get('/api/cities/modal', config);
    }

    /**
     * Получить список всех городов
     * @param [queryParams] - квери-параметры запроса
     * @param config
     */
    static fetchAllCities(queryParams?: GetAllCitiesRequestQuery, config?: RequestConfig): Promise<AllCitiesList> {
        return api.get('/api/cities', {
            ...config,
            params: { ...config?.params, ...queryParams }
        });
    }

    /**
     * Получить список всех городов
     * @param [id] - ID города
     * @param config
     */
    static fetchById<R = CityDTO|null>(id: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/cities/${ id }`, config));
    }
}
