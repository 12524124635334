//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Typograph from '@/components/Typograph';
import CheckItem from '@/components/CheckItem';
import makeClasses from '@/helpers/makeClasses';

const THEMES = {
    base: 'base',
    blue: 'blue',
    white: 'white',
    simple_white: 'simple_white',
    light_gray_blue: 'light_gray_blue',
    none: 'none',
    global: 'global'
};
const FONT_THEMES = {
    base: 'base',
    md: 'md',
    large: 'large'
};
const VIEWS = {
    base: 'base',
    lg: 'lg',
    simple: 'simple'
};
export { THEMES, FONT_THEMES, VIEWS };

const useClasses = makeClasses(
    () => ({
        label: ({ theme, checked, fontTheme, withMark, withBackground, view, disabled, isMobile }) => ({
            'relative transition-medium select-none': true,
            'cursor-pointer': !checked,
            'cursor-default': checked,
            'flex items-center -mx-1.5': withMark,
            'pointer-events-none': disabled,
            'w-full': isMobile,
            // VIEWS
            'py-2 px-2.5 rounded-md': view === VIEWS.base,
            'py-2.5 px-4 rounded-lg': view === VIEWS.lg,
            // FONT_THEMES
            'font-normal text-sm': fontTheme === FONT_THEMES.base,
            'text-base': fontTheme === FONT_THEMES.md,
            'text-xl xl:text-lg': fontTheme === FONT_THEMES.large,
            // THEMES
            // base, white, blue
            'text-black': ((theme === THEMES.base && checked) || (theme === THEMES.blue && !checked) || theme === THEMES.white) && theme !== THEMES.global,
            'text-primary': theme === THEMES.global,
            'bg-primary text-substrate': theme === THEMES.global && checked,
            'text-white': theme === THEMES.base && !checked,
            'bg-white': (theme === THEMES.base || theme === THEMES.white) && checked && withBackground,
            // simple_white
            'text-white hover:text-opacity-100': theme === THEMES.simple_white && checked,
            'text-white hover:text-opacity-80 text-opacity-50': theme === THEMES.simple_white && !checked,
            // blue,
            // light_gray_blue
            'before:absolute before:inset-0 before:transition-medium before:block before:border before:border-blue-250 before:rounded-inherit before:opacity-0': (theme === THEMES.light_gray_blue) || (theme === THEMES.blue),
            'text-blue': (theme === THEMES.blue && checked) || (theme === THEMES.light_gray_blue && checked),
            'bg-blue-100 before:opacity-100': (theme === THEMES.blue && checked && withBackground) || (theme === THEMES.light_gray_blue && checked),
            'text-black bg-black bg-opacity-5': theme === THEMES.light_gray_blue && !checked
        }),
        input: () => ({
            'absolute inset-0 opacity-0 pointer-events-none': true
        }),
        content: ({ withMark }) => ({
            'relative inline-flex typography': true,
            'mx-1.5': withMark
        }),
        mark: () => 'mx-1.5'
    })
);

export default {
    components: { Typograph, CheckItem },
    props: {
        title: { type: String },
        isMobile: { type: Boolean, default: false },
        value: { type: [Boolean, String, Number, Array] },
        /** значение для радиокнопок */
        fieldValue: { type: [String, Number] },
        type: {
            default: 'checkbox',
            validator(value) {
                return ['checkbox', 'radio'].includes(value);
            }
        },
        theme: {
            default: THEMES.base,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        fontTheme: {
            default: FONT_THEMES.base,
            validator(value) {
                return Object.keys(FONT_THEMES).includes(value);
            }
        },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        disabled: { type: Boolean, required: false, default: false },
        withMark: { type: Boolean, default: false, required: false },
        activeWithBackground: { type: Boolean, default: false, required: false }
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isChecked() {
            return this.fieldValue
                ? Array.isArray(this.inputValue)
                    ? this.inputValue.includes(this.fieldValue)
                    : this.inputValue === this.fieldValue
                : !!this.inputValue;
        },
        isCheckbox() {
            return this.type === 'checkbox';
        },
        classes() {
            return useClasses({
                isMobile: this.isMobile,
                theme: this.theme,
                checked: this.isChecked,
                isCheckbox: this.isCheckbox,
                fontTheme: this.fontTheme,
                view: this.view,
                disabled: this.disabled,
                withMark: this.withMark,
                withBackground: this.withBackground
            });
        },
        withBackground() {
            return this.isCheckbox || this.activeWithBackground;
        }
    }
};
