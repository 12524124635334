//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseTooltip from '~/components/BaseTooltip';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';
import { userStore } from '~/store';

export default {
    components: { BaseTooltip, BaseButton, BaseIcon },
    props: {
        theme: {
            default: 'transparent',
            validator(value) {
                return ['transparent', 'white'].includes(value);
            }
        },
        city: { type: Object, required: true },
        autoOpen: { type: Boolean },
        tooltipZIndex: { type: Number, default: 9999 },
        alwaysShowCity: { type: Boolean, default: false }
    },
    methods: {
        /**
         * Подтвердить город определенный по IP
         * @param hideTooltipFn - ф-ия для скрывания тултипа
         */
        handleCityConfirm(hideTooltipFn) {
            this.$cookies.set('userCity', this.city, {
                maxAge: 60 * 60 * 24 * 365,
                path: '/'
            });
            userStore.updateIsShowDetectedCity(false);
            hideTooltipFn();
        }
    }
};
