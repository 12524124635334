//
//
//
//
//
//
//
//
//
//
//
//

import ToggleGroup from '~/components/form/ToggleGroup';
import { userStore } from '~/store';
import { UserTypes } from '@/models/enums';

const SPECIAL_ROUTE_NAMES = [
    'application',
    'application-two',
    'application-three',
    'application-four',
    'application-five',
    'application-six',
    'application-finish'
];

export default {
    components: { ToggleGroup },
    props: {
        userTypes: {
            type: Array,
            required: true
        },
        toggleView: {
            type: String,
            default: 'base'
        },
        callToAction: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        userType: {
            get() { return userStore.userType; }
        }
    },
    watch: {
        $route: function(to) {
            // if (!to.query.userType) return;

            // try {
            //     const newUserType = parseInt(to.query.userType);
            //     if (newUserType !== this.userType && !SPECIAL_ROUTE_NAMES.includes(this.$route.name)) {
            //         this.updateUserType(newUserType);
            //     }
            // } catch (err) { // silent err
            //     console.error(err);
            // }
        },
        userType(val) {
        }
    },
    methods: {
        updateUserType(userType) {
            this.$cookies.set('userType', userType, {
                maxAge: 60 * 60 * 24 * 365,
                path: '/'
            });
            userStore.updateUserType(userType);
            this.$nuxt.$emit('manuallyUpdateUserType', true);
        },
        async handleChange(value) {
            this.updateUserType(value);
            if (SPECIAL_ROUTE_NAMES.includes(this.$route.name)
            || this.$route.name === 'business' && value === UserTypes.private) {
                await this.$router.push({ name: 'index' });
            } else if (this.$route.name === 'index' && value === UserTypes.company) {
                await this.$router.push({ name: 'business' });
            }
        },
        async handleClick() {
            if (SPECIAL_ROUTE_NAMES.includes(this.$route.name)) {
                this.$layer.confirm({
                    title: 'Вы уверены, что хотите изменить тип?',
                    message: 'Мы переправим вас на главную, все несохраненные данные могут быть потеряны',
                    acceptButtonCaption: 'Изменить',
                    declineButtonCaption: 'Отмена',
                    icon: {
                        name: 'question',
                        class: 'text-blue',
                        width: 128,
                        height: 128
                    }
                }).then(async(confirm) => {
                    if (!confirm) return;
                    const newType = this.userTypes.filter(item => item.id !== this.userType);
                    newType?.[0]?.id && await this.handleChange(newType[0].id);
                });
            } else {
                const newType = this.userTypes.filter(item => item.id !== this.userType);
                newType?.[0]?.id && await this.handleChange(newType[0].id);
            }
        }
    }
};
