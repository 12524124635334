import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator';
import { LAYOUT_THEMES } from '~/constants/layout';

interface LayoutState {
    _headerTheme: 'transparent'|'white';
    _theme: LAYOUT_THEMES;
}

@Module({
    name: 'layout',
    stateFactory: true,
    namespaced: true
})
export default class LayoutStore extends VuexModule {
    private _theme: LayoutState['_theme'] = LAYOUT_THEMES.DEFAULT;

    @VuexMutation
    private _updateTheme(theme: LayoutState['_theme']): void {
        this._theme = theme;
    }

    @VuexAction
    changeTheme(theme: LAYOUT_THEMES): void {
        this._updateTheme(theme);
    }

    get activeTheme(): LayoutState['_theme'] {
        return this._theme;
    }


    private _headerTheme: LayoutState['_headerTheme'] = 'transparent';

    @VuexMutation
    private _updateHeaderTheme(payload: LayoutState['_headerTheme']): void {
        this._headerTheme = payload;
    }

    @VuexAction
    updateHeaderTheme(payload: LayoutState['_headerTheme']): void {
        this._updateHeaderTheme(payload);
    }

    get headerTheme(): LayoutState['_headerTheme'] {
        return this._headerTheme;
    }
}
