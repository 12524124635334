export enum STATISTIC_ACTIONS {
    PAGE_LOAD = 'page-load', // (1)[1,2,3] - загрузка страницы
    FILTER_CHOICE = 'filter-choice', // (1)[1,2,3,5,6,7] - выбор типа продукта в фильтре (поиск)
    PRODUCT_CHOICE = 'product-choice', // (1)[1,2,3,4,6,7] - выбор продукта при заполнении заявки
    APPLICATION_ID_APPROVED = 'application.id-approved', // (2)[1,2,3] - подтверждение данных на форме заявки
    APPLICATION_FILLED = 'application.filled', // (2)[1,2,3] - форма заполнена и отправлена
    APPLICATION_APPROVED = 'application.approved', // (2)[1,2,3] - заявка одобрена
    LIKE = 'page-like',
    DISLIKE = 'page-dislike',
}
