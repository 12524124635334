import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator';
import { PageData } from '~/models/Page';
import PageService from '~/services/Page';
import { CityDTO } from '~/models/Cities';
import CityService from '~/services/City';
import { FormDataDTO } from '~/models/Common';
import CommonService from '~/services/Common';

interface CommonState {
    _headerMenu: Array<PageData> | null;
    _footerMenu: Array<PageData> | null;
    _predefinedCities: Array<CityDTO> | null;
    _feedbackFormData: FormDataDTO | null;
    _isChatInit: boolean;
    _isCookieAccepted: boolean;
}

@Module({
    // Имя стора должно совпадать с именем файла
    name: 'common',

    // Эти два параметра обязательны. Без них работать не будет.
    stateFactory: true,
    namespaced: true
})
export default class CommonStore extends VuexModule {
    private _headerMenu: CommonState['_headerMenu'] = null;
    private _footerMenu: CommonState['_footerMenu'] = null;
    private _predefinedCities: CommonState['_predefinedCities'] = null;
    private _feedbackFormData: CommonState['_feedbackFormData'] = null;
    private _isChatInit: CommonState['_isChatInit'] = false;
    private _isCookieAccepted: CommonState['_isCookieAccepted'] = false;

    // HeaderMenu
    @VuexMutation
    // Мутиации тоже указываем с `_`, чтобы не пересекалось с экшенами и геттерами
    // Не забываем указывать что это приватное поле `private`, чтобы извне не было возможности поменять данные
    private _updateHeaderMenu(payload: CommonState['_headerMenu']): void {
        this._headerMenu = payload;
    }

    @VuexAction
    async fetchHeaderMenu(): Promise<void> {
        try {
            const headerMenuData = await PageService.fetchData('headerMenu');
            if (headerMenuData && headerMenuData.children) { this._updateHeaderMenu(headerMenuData.children); }
        } catch (err) {
            console.error(err);
        }
    }

    get headerMenu(): CommonState['_headerMenu'] {
        return this._headerMenu;
    }

    // FooterMenu
    @VuexMutation
    private _updateFooterMenu(payload: CommonState['_footerMenu']): void {
        this._footerMenu = payload;
    }

    @VuexAction
    async fetchFooterMenu(): Promise<void> {
        try {
            const footerMenuData = await PageService.fetchData('footerMenu');
            if (footerMenuData && footerMenuData.children) { this._updateFooterMenu(footerMenuData.children); }
        } catch (err) {
            console.error(err);
        }
    }

    get footerMenu(): CommonState['_footerMenu'] {
        return this._footerMenu;
    }

    // PredefinedCities
    @VuexMutation
    private _updatePredefinedCities(payload: CommonState['_predefinedCities']): void {
        this._predefinedCities = payload;
    }

    @VuexAction
    async fetchPredefinedCities(): Promise<void> {
        try {
            const predefinedCities = await CityService.fetchPredefinedCities();
            if (predefinedCities) { this._updatePredefinedCities(predefinedCities); }
        } catch (err) {
            console.error(err);
        }
    }

    get predefinedCities(): CommonState['_predefinedCities'] {
        return this._predefinedCities;
    }

    // FeedbackFormData
    @VuexMutation
    private _updateFeedbackFormData(payload: CommonState['_feedbackFormData']): void {
        this._feedbackFormData = payload;
    }

    @VuexAction
    async fetchFeedbackFormData(): Promise<void> {
        try {
            const formData = await CommonService.fetchFormData('support');
            if (formData) { this._updateFeedbackFormData(formData); }
        } catch (err) {
            console.error(err);
        }
    }

    get feedbackFormData(): CommonState['_feedbackFormData'] {
        return this._feedbackFormData;
    }

    // IsChatInit
    @VuexMutation
    private _updateIsChatInit(payload: CommonState['_isChatInit']): void {
        this._isChatInit = payload;
    }

    @VuexMutation
    private _updateIsCookieAccepted(payload: CommonState['_isCookieAccepted']): void {
        this._isCookieAccepted = payload;
    }

    @VuexAction
    updateIsChatInit(payload: CommonState['_isChatInit']): void {
        this._updateIsChatInit(payload);
    }

    @VuexAction
    updateIsCookieAccepted(payload: CommonState['_isCookieAccepted']): void {
        this._updateIsCookieAccepted(payload);
    }

    get isChatInit(): CommonState['_isChatInit'] {
        return this._isChatInit;
    }

    get isCookieAccepted(): CommonState['_isCookieAccepted'] {
        return this._isCookieAccepted;
    }
}
