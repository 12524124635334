//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseIcon from '@/components/BaseIcon';

export default {
    components: { BaseIcon },
    props: {
        isChecked: { type: Boolean, default: false }
    }
};
