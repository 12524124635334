//
//
//

import ContentJavascript from '@/components/Content/ContentJavascript';

export default {
    name: 'ChatCarrotQuest',
    components: { ContentJavascript },
    storageItems: ['chatCarrotQuest'],
    data() {
        return {};
    },
    fetch() {},
    computed: {
        script() {
            return this.chatCarrotQuest?.code;
        }
    }
};
