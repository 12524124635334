import { Context } from '@nuxt/types';
import { USER_TYPE_ID_COOKIE_NAME, USER_TYPE_SLUGS } from '~/constants/user';
import { UserTypes } from '~/models/enums';
import { userStore } from '~/store';

export default function({ route, redirect, $cookies }: Context): void {
    const userTypeNumber: number|undefined = $cookies.get(USER_TYPE_ID_COOKIE_NAME);

    if (route.name === 'index' && userTypeNumber === UserTypes.company) {
        redirect({ name: 'business' });
    }

    // if (route.name === 'external-frame') {
    //     return;
    // }
    // const userType = route.params.userType;
    // let userTypeFromRoute = null;
    // if (userType === USER_TYPE_SLUGS.PRIVATE) {
    //     userTypeFromRoute = UserTypes.private;
    // } else if (userType === USER_TYPE_SLUGS.COMPANY) {
    //     userTypeFromRoute = UserTypes.company;
    // }
    //
    // if (userTypeFromRoute !== null) {
    //     $cookies.set(USER_TYPE_ID_COOKIE_NAME, userTypeFromRoute, {
    //         maxAge: 60 * 60 * 24 * 365,
    //         path: '/'
    //     });
    //     userStore.updateUserType(userTypeFromRoute);
    // }
    //
    // const userTypeNumber: number|undefined = userTypeFromRoute || $cookies.get(USER_TYPE_ID_COOKIE_NAME);
    //
    // if (!userType || !([USER_TYPE_SLUGS.PRIVATE, USER_TYPE_SLUGS.COMPANY].map(item => String(item))).includes(userType)) {
    //     const fullPath = route.fullPath;
    //     let currentUserSlug = USER_TYPE_SLUGS.PRIVATE;
    //     if (userTypeNumber && UserTypes.company === userTypeNumber) {
    //         currentUserSlug = USER_TYPE_SLUGS.COMPANY;
    //     }
    //     redirect(`/${ currentUserSlug }${ fullPath.startsWith('/') ? fullPath : `/${ fullPath }` }`);
    // }
}
