//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const THEMES = {
    'gray-50': 'gray-50',
    light: 'light'
};

export default {
    props: {
        placement: { type: String, default: 'bottom', required: false },
        autoOpen: { type: Boolean },
        theme: {
            type: String,
            default: THEMES['gray-50'],
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        }
    },
    computed: {
        tippyInstance() {
            return this.$refs.tippy;
        }
    },
    mounted() {
        if (this.autoOpen) {
            this.$nextTick(() => {
                this.tippyInstance && this.tippyInstance.tippy().show();
            });
        }
    },
    methods: {
        hide() {
            if (this.tippyInstance && this.tippyInstance.tippy) {
                this.tippyInstance.tippy().hide();
            }
        }
    }
};
