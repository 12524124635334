//
//
//
//

export default {
    inheritAttrs: false,
    props: {
        name: { type: String, required: true }
    }
};
