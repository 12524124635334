
export default {
    name: 'ContentJavascript',
    props: {
        block: { type: Object, required: true }
    },
    mounted() {
        if (!this.block.data) {
            return;
        }

        const temporaryScripts = this.$el.querySelectorAll('script');

        temporaryScripts.forEach(script => {
            const scriptTag = document.createElement('script');
            [...script.attributes].map((attr) => {
                scriptTag.setAttribute(attr.name, attr.value);
            });
            scriptTag.innerHTML = script.innerHTML;

            script.insertAdjacentElement('afterend', scriptTag);
            script.remove();
        });
    },
    render(createElement) {
        // рендерим только на клиенте
        if (process.server || !this.block.data) {
            return createElement('div');
        }

        return createElement('div', {
            domProps: {
                innerHTML: this.block.data
            }
        });
    }
};
