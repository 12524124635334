import { render, staticRenderFns } from "./default.vue?vue&type=template&id=51432224&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/app/components/TheHeader.vue').default,ChatCarrotQuest: require('/app/components/ChatCarrotQuest.vue').default,TheFooter: require('/app/components/TheFooter.vue').default,CookieAlert: require('/app/components/CookieAlert.vue').default})
