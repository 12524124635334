import api, { apiFetch, FetchResult, RequestConfig, RequestDataNoArg } from '~/helpers/api';

export default class StatisticsService {
    /**
     * Запись действия
     * @param data - тело запроса
     * @param config - настройки запроса
     */
    static hit<R = object>(data: RequestDataNoArg, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.post('/api/statistics/hit', data, { ...(config || {}), progress: false }));
    }
}
