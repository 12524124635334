/**
 * Список тем оформления
 * @readonly
 * @enum {string}
 */
export enum LAYOUT_THEMES {
    DEFAULT = 'theme-gray-blue',
    GRAY_BLUE = 'theme-gray-blue',
    WHITE = 'theme-white',
    GRAY = 'theme-gray',
    BLACK = 'theme-black',
    BLUE = 'theme-blue',
}
