//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '@/mixins/documentSizeMixin';
import Typograph from '@/components/Typograph';
import BaseButton from '@/components/BaseButton';
import { commonStore } from '~/store';

export const DISCLAIMER_COOKIE_KEY = '_cda';

export default {
    components: { BaseButton, Typograph },
    mixins: [documentSizeMixin],
    storageItems: ['policies'],
    data() {
        return {
            isActive: false
        };
    },
    computed: {
        content() {
            return this.policies?.cookieDisclaimer || '';
        }
    },
    mounted() {
        this.isActive = true;
    },
    methods: {
        doClose() {
            commonStore.updateIsCookieAccepted(true);
            this.$cookies.set(DISCLAIMER_COOKIE_KEY, 1);
            this.isActive = false;
        }
    }
};
