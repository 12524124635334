import { v4 as uuidv4 } from 'uuid';
import { NuxtCookies } from 'cookie-universal-nuxt';
import { USER_IDENTIFIER_COOKIE_NAME } from '~/constants/user';

export default function setUserIdentifierIfNotExists(cookieManager: NuxtCookies): string {
    let userSessionId = cookieManager.get(USER_IDENTIFIER_COOKIE_NAME);
    if (!userSessionId) {
        userSessionId = uuidv4();
        cookieManager.set(USER_IDENTIFIER_COOKIE_NAME, userSessionId);
    }

    return userSessionId;
}
