export enum UserTypes {
    'private'= 1,
    'company'
}

export const EMPTY_PAGINATED_LIST = {
    items: [],
    pagination: {
        page: 1,
        pages: 0,
        total: 0
    }
};

/** Секции в заявке заемщика */
export enum ApplicationFormSectionCodes {
    personalData = 'personalData', // 'Персональные данные',
    passportData = 'passportData', // 'Паспортные данные,
    familyInformation = 'familyInformation', // 'Сведения о семье',
    incomeInformation = 'incomeInformation', // 'Сведения о доходах',
    vehicleInformation = 'vehicleInformation', // 'Сведения о транспортном средстве',
    loanInformation = 'loanInformation', // 'Информация о займе',
    legalInformation = 'legalInformation', // 'Юридическая информация',
    realEstateInformation = 'realEstateInformation', // ' Сведения о недвижимости',
    informationAboutEquipment = 'informationAboutEquipment', // 'Сведения об оборудовании',
    contractDetails = 'contractDetails', // 'Сведения о контракте',
    informationAboutSoleProprietor = 'informationAboutSoleProprietor', // 'Сведения об ИП',
    informationAboutLegalEntity = 'informationAboutLegalEntity', // 'Сведения о юридическом лице (ООО)',
    informationAboutBorrower = 'informationAboutBorrower' // 'Сведения о заемщике',
}

/** Статусы заявки заемщика */
export enum ApplicationStatuses {
    onFilling = 0, // 'Заявка на заполнении',
    underConsideration = 1, // 'Заявка на рассмотрении',
    approved = 2, // 'Заявка одобрена',
    rejected = 3, // 'Заявка отклонена',
    lenderDoesNotRespond = 4, // 'Кредитор не отвечает',
    loanHasBeenIssued = 5, // 'Выдан кредит',
    clientRefused = 6, // 'Клиент отказался',
    supplementedByPartner = 7 // 'Заявка дополнена партнером',
}
