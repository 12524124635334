//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        width: {
            type: Number,
            default: 14
        },
        theme: {
            default: 'white',
            validator(value) {
                return ['white', 'black', 'blue', 'gray'].includes(value);
            }
        }
    },
    computed: {
        classes() {
            return {
                root: {
                    'ios-loader': true,
                    'ios-loader_theme_black': this.theme === 'black',
                    'ios-loader_theme_blue': this.theme === 'blue',
                    'ios-loader_theme_gray': this.theme === 'gray'

                }
            };
        }
    }
};
