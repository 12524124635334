//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import makeClasses from '@/helpers/makeClasses';
import BaseToggle, { FONT_THEMES } from '@/components/form/BaseToggle';
import Typograph from '@/components/Typograph';
import BaseIcon from '@/components/BaseIcon';

const THEMES = {
    base: 'base',
    white: 'white',
    simple: 'simple',
    simple_light: 'simple_light',
    white_outlined: 'white_outlined',
    global: 'global'
};
const TOGGLE_THEMES = {
    base: 'base',
    blue: 'blue',
    simple_white: 'simple_white',
    white: 'white',
    global: 'global'
};
const SIZES = {
    base: 'base',
    md: 'md',
    sm: 'sm'
};
const VIEWS = {
    base: 'base',
    line: 'line',
    circle: 'circle'
};
export { THEMES, TOGGLE_THEMES, SIZES, VIEWS };

const useClasses = makeClasses(
    () => ({
        root: ({ theme, size, view, disabled }) => ({
            'relative flex p-0.5': true,
            'rounded-md': view === VIEWS.base || view === VIEWS.line,
            'rounded-5xl': view === VIEWS.circle,
            'pointer-events-none': disabled,
            'bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg': theme === THEMES.base, // #FFFFFF 10%
            'bg-white': theme === THEMES.white, // #FFFFFF
            'bg-black bg-opacity-10': theme === THEMES.simple, // #101010 10%
            'bg-black bg-opacity-5': theme === THEMES.simple_light, // #101010 5%
            'bg-white before:block before:absolute before:inset-0 before:border before:border-gray-100 before:rounded-inherit before:pointer-events-none': theme === THEMES.white_outlined,
            'bg-accent text-primary bg-opacity-[var(--theme-accent-opacity)]': theme === THEMES.global,
            'gap-y-1': size === SIZES.base,
            'gap-y-2': size === SIZES.md,
            'flex-wrap': view === VIEWS.base
        }),
        activeRadio: ({ toggleTheme, view }) => ({
            'absolute top-0.5 left-0 bottom-0.5 pointer-events-none': true,
            'rounded-md': view === VIEWS.base || view === VIEWS.line,
            'rounded-5xl': view === VIEWS.circle,
            'bg-white': toggleTheme === TOGGLE_THEMES.base || toggleTheme === TOGGLE_THEMES.white,
            'bg-blue-100 shadow-blue-250-outlined': toggleTheme === TOGGLE_THEMES.blue
        }),
        toggle: ({ isRadioGroup }) => ({
            relative: true,
            'mr-1 last:mr-0': !isRadioGroup
        })
    })
);
const CALL_TO_ACTION_TRANSLATE_OFFSET = 15;

export default {
    components: { BaseToggle, Typograph, BaseIcon },
    props: {
        items: { type: Array, required: true },
        value: { type: [Boolean, String, Number, Array], default: null },
        type: {
            default: 'checkbox',
            validator(value) {
                return ['checkbox', 'radio'].includes(value);
            }
        },
        theme: {
            default: THEMES.base,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        toggleTheme: {
            default: TOGGLE_THEMES.base,
            validator(value) {
                return Object.keys(TOGGLE_THEMES).includes(value);
            }
        },
        size: {
            default: SIZES.base,
            validator(value) {
                return Object.keys(SIZES).includes(value);
            }
        },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        fontTheme: {
            default: FONT_THEMES.base,
            validator(value) {
                return Object.keys(FONT_THEMES).includes(value);
            }
        },
        disabled: { type: Boolean, required: false, default: false },
        toggleMod: { type: String, default: '', required: false },
        withMotion: { type: Boolean, default: true, required: false },
        hideActiveTitle: { type: Boolean, default: false, required: false },
        callToAction: { type: Boolean, default: false, required: false }
    },
    data() {
        return {
            activeToggleRect: {
                offsetLeft: 0,
                width: 0
            },
            activeToggleRectOffsetLeftWas: 0,
            transitionClass: 'transition-medium',
            callToActionIntervalTimer: null,
            callToActionAnimationTimer: null,
            SIZES: SIZES
        };
    },
    computed: {
        modelInputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isRadioGroup() {
            return this.type === 'radio';
        },
        classes() {
            return useClasses({
                theme: this.theme,
                isRadioGroup: this.isRadioGroup,
                toggleTheme: this.toggleTheme,
                size: this.size,
                view: this.view,
                disabled: this.disabled
            });
        },
        isActiveToggleWithMotion() {
            if (!this.isRadioGroup) { return false; }

            return this.withMotion;
        },
        activeAnimation() {
            const activeToggleIndex = this.items.findIndex(toggle => toggle.id === this.modelInputValue);

            return {
                animationDuration: '5s',
                animationName: activeToggleIndex === 0 ? 'slideRightAndReturn' : 'slideLeftAndReturn'
            };
        },
        activeTabIndex() {
            return this.items.findIndex(toggle => toggle.id === this.modelInputValue);
        }
    },
    watch: {
        modelInputValue: {
            handler(val) {
                this.handleActiveToggleChange(val);
                if (this.hideActiveTitle) {
                    setTimeout(() => {
                        this.handleActiveToggleChange(val);
                    },  400);
                }
            }
        }
    },
    mounted() {
        this.handleActiveToggleChange(this.modelInputValue);
        window.addEventListener('resize', this.onWindowResize);
        if (this.callToAction) {
            setTimeout(() => {
                this.startCallToActionInterval();
            }, 1000);
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
        this.stopCallToAction();
    },
    methods: {
        async handleActiveToggleChange(val) {
            const activeToggleIndex = this.items.findIndex(toggle => toggle.id === val);

            if (activeToggleIndex !== -1) {
                this.transitionClass = 'transition-medium';
                const activeToggleComponent = this.$refs.toggle[activeToggleIndex];
                const activeToggleLabel = activeToggleComponent.$refs.label;

                if (!activeToggleLabel) { return; }

                const activeToggleDOMRect = activeToggleLabel.getBoundingClientRect();
                const containerOffsetLeft = this.$refs.container.getBoundingClientRect().left;
                const offsetLeft = activeToggleDOMRect.left - containerOffsetLeft;
                this.activeToggleRectOffsetLeftWas = offsetLeft;
                this.activeToggleRect = {
                    offsetLeft,
                    width: activeToggleDOMRect.width
                };
            }
        },
        onWindowResize() {
            this.handleActiveToggleChange(this.modelInputValue);
        },
        startCallToActionInterval() {
            this.stopCallToAction();
            this.callToActionIntervalTimer = setInterval(() => {
                this.transitionClass = 'transition-5x';
                this.startCallToAction();
            }, 5000);
        },
        startCallToAction() {
            this.activeToggleRect.offsetLeft = this.activeTabIndex === 0 ? this.activeToggleRect.offsetLeft + CALL_TO_ACTION_TRANSLATE_OFFSET : this.activeToggleRect.offsetLeft - CALL_TO_ACTION_TRANSLATE_OFFSET;
            this.callToActionAnimationTimer = setTimeout(() => {
                this.activeToggleRect.offsetLeft = this.activeToggleRectOffsetLeftWas;
            }, 1000);
        },
        stopCallToAction() {
            clearInterval(this.callToActionIntervalTimer);
            clearTimeout(this.callToActionAnimationTimer);
            this.transitionClass = 'transition-medium';
            this.activeToggleRect.offsetLeft = this.activeToggleRectOffsetLeftWas;
        }
    }
};
