var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:{
        'w-6 h-6 relative flex items-center justify-center flex-shrink-0 fill-current text-white rounded transition-fast': true,
        'bg-blue shadow-none': _vm.isChecked,
        'shadow-gray-650-outlined': !_vm.isChecked
    }},[_c('BaseIcon',{class:{
            'transition-fast': true,
            'opacity-100': _vm.isChecked,
            'opacity-0': !_vm.isChecked
        },attrs:{"name":"check","width":"13","height":"12"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }