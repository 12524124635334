import Vue from 'vue'
import delayHydration from './hydration/hydrationRace.mjs'

import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_54604f30 from 'nuxt_plugin_plugin_54604f30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_1254e4e2 from 'nuxt_plugin_nuxtuseragent_1254e4e2' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_7007b3d1 from 'nuxt_plugin_deviceplugin_7007b3d1' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_70cffde4 from 'nuxt_plugin_nuxtsvgsprite_70cffde4' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_store_336ed1aa from 'nuxt_plugin_store_336ed1aa' // Source: ../plugins/store (mode: 'all')
import nuxt_plugin_axios_5659cf26 from 'nuxt_plugin_axios_5659cf26' // Source: ../plugins/axios.ts (mode: 'all')
import nuxt_plugin_page_922d63f0 from 'nuxt_plugin_page_922d63f0' // Source: ../plugins/page.ts (mode: 'all')
import nuxt_plugin_layerclient_1f623f7b from 'nuxt_plugin_layerclient_1f623f7b' // Source: ../plugins/layer/layer.client.js (mode: 'client')
import nuxt_plugin_storage_8a363cdc from 'nuxt_plugin_storage_8a363cdc' // Source: ../plugins/storage.js (mode: 'all')
import nuxt_plugin_uuid_3cb5c548 from 'nuxt_plugin_uuid_3cb5c548' // Source: ../plugins/uuid.ts (mode: 'all')
import nuxt_plugin_useridentifier_731e2958 from 'nuxt_plugin_useridentifier_731e2958' // Source: ../plugins/user-identifier.ts (mode: 'all')
import nuxt_plugin_datepicker_4a26849c from 'nuxt_plugin_datepicker_4a26849c' // Source: ../plugins/datepicker (mode: 'client')
import nuxt_plugin_vueslider_41578b8e from 'nuxt_plugin_vueslider_41578b8e' // Source: ../plugins/vue-slider.js (mode: 'client')
import nuxt_plugin_lazyload_10f2b546 from 'nuxt_plugin_lazyload_10f2b546' // Source: ../plugins/lazyload.ts (mode: 'client')
import nuxt_plugin_vuetippy_1467d320 from 'nuxt_plugin_vuetippy_1467d320' // Source: ../plugins/vue-tippy.js (mode: 'client')
import nuxt_plugin_vuetoastification_4e3fd043 from 'nuxt_plugin_vuetoastification_4e3fd043' // Source: ../plugins/vue-toastification.js (mode: 'client')
import nuxt_plugin_vuestickydirective_fd68eca2 from 'nuxt_plugin_vuestickydirective_fd68eca2' // Source: ../plugins/vue-sticky-directive.js (mode: 'client')
import nuxt_plugin_vuetextareaautosize_1be18711 from 'nuxt_plugin_vuetextareaautosize_1be18711' // Source: ../plugins/vue-textarea-autosize.js (mode: 'client')
import nuxt_plugin_auth_7543862a from 'nuxt_plugin_auth_7543862a' // Source: ./auth.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
const exclude = ['/external-frame/'];
const url = ssrContext ? ssrContext.url : window.location.pathname;
const excludeCond = Boolean(exclude.find(v => url.includes(v)));

if (!excludeCond) {
    if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
        await delayHydration()
    }
}

  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    if (process.server) {
      // meta.push({ hid: 'og:image', name: 'og:image', content: `//${ this.context.req.headers.host }/social.jpg` });
    }
    const facebookPixelId = process.env.FACEBOOK_PIXEL_ID;

    return {
      title: this.$store.getters['page/metaTitle'] || 'App',
      meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { hid: 'msapplication-TileColor', name: 'msapplication-TileColor', content: '#da532c' },
      { hid: 'theme-color', name: 'theme-color', content: '#ffffff' },
      { name: 'google-site-verification', content: '9TzxkGMfrJo0AGTZAurTYRwtqeVtIzbU5hN50raz6cM' },
      ...this.$store.getters['page/metaInfo']
      // ...meta
      ],
      link: [
      { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
      { rel: 'manifest', href: '/site.webmanifest' },
      { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' }],

      script: [
      {
        once: true,
        defer: true,
        hid: 'meta-pixel',
        innerHTML: `
                        !(function (f, b, e, v, n, t, s) {
                          if (f.fbq) return;
                          n = f.fbq = function () {
                            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                          };
                          if (!f._fbq) f._fbq = n;
                          n.push = n;
                          n.loaded = !0;
                          n.version = "2.0";
                          n.queue = [];
                          t = b.createElement(e);
                          t.async = !0;
                          t.src = v;
                          s = b.getElementsByTagName(e)[0];
                          s.parentNode.insertBefore(t, s);
                        })(
                          window,
                          document,
                          "script",
                          "https://connect.facebook.net/en_US/fbevents.js"
                        );
                        fbq("init", "${facebookPixelId}");
                        fbq("track", "PageView");
                    `
      },
      {
        once: true,
        defer: true,
        src: 'https://yandex.ru/ads/system/context.js'
      },
      {
        once: true,
        defer: true,
        innerHTML: `
                        window.yaContextCb=window.yaContextCb||[];
                    `
      }],

      noscript: [
      {
        hid: 'meta-pixel-noscript',
        innerHTML: `<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" /></noscript>`
      }],

      __dangerouslyDisableSanitizersByTagID: {
        'meta-pixel': ['innerHTML'],
        'meta-pixel-noscript': ['innerHTML']
      }
    };
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_54604f30 === 'function') {
    await nuxt_plugin_plugin_54604f30(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_1254e4e2 === 'function') {
    await nuxt_plugin_nuxtuseragent_1254e4e2(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_7007b3d1 === 'function') {
    await nuxt_plugin_deviceplugin_7007b3d1(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_70cffde4 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_70cffde4(app.context, inject)
  }

  if (typeof nuxt_plugin_store_336ed1aa === 'function') {
    await nuxt_plugin_store_336ed1aa(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659cf26 === 'function') {
    await nuxt_plugin_axios_5659cf26(app.context, inject)
  }

  if (typeof nuxt_plugin_page_922d63f0 === 'function') {
    await nuxt_plugin_page_922d63f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_layerclient_1f623f7b === 'function') {
    await nuxt_plugin_layerclient_1f623f7b(app.context, inject)
  }

  if (typeof nuxt_plugin_storage_8a363cdc === 'function') {
    await nuxt_plugin_storage_8a363cdc(app.context, inject)
  }

  if (typeof nuxt_plugin_uuid_3cb5c548 === 'function') {
    await nuxt_plugin_uuid_3cb5c548(app.context, inject)
  }

  if (typeof nuxt_plugin_useridentifier_731e2958 === 'function') {
    await nuxt_plugin_useridentifier_731e2958(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_datepicker_4a26849c === 'function') {
    await nuxt_plugin_datepicker_4a26849c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueslider_41578b8e === 'function') {
    await nuxt_plugin_vueslider_41578b8e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyload_10f2b546 === 'function') {
    await nuxt_plugin_lazyload_10f2b546(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetippy_1467d320 === 'function') {
    await nuxt_plugin_vuetippy_1467d320(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetoastification_4e3fd043 === 'function') {
    await nuxt_plugin_vuetoastification_4e3fd043(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuestickydirective_fd68eca2 === 'function') {
    await nuxt_plugin_vuestickydirective_fd68eca2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetextareaautosize_1be18711 === 'function') {
    await nuxt_plugin_vuetextareaautosize_1be18711(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7543862a === 'function') {
    await nuxt_plugin_auth_7543862a(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.push(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
