import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=6a2790a0&"
import script from "./TheHeader.vue?vue&type=script&lang=js&"
export * from "./TheHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/app/components/BaseIcon.vue').default,LogoItem: require('/app/components/LogoItem.vue').default,UserTypeToggle: require('/app/components/UserTypeToggle.vue').default,BaseTooltip: require('/app/components/BaseTooltip.vue').default,CityTooltip: require('/app/components/CityTooltip.vue').default})
