//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Vue } from 'nuxt-property-decorator';
import BaseIcon from '~/components/BaseIcon';
import BaseTooltip from '~/components/BaseTooltip';
import LogoItem from '~/components/LogoItem';
import CityTooltip from '~/components/CityTooltip';
import UserTypeToggle from '~/components/UserTypeToggle';

import { userStore } from '~/store';
import { UserTypes } from '@/models/enums';

export default Vue.extend({
    components: { LogoItem, BaseIcon, BaseTooltip, CityTooltip, UserTypeToggle },
    props: {
        navItems: { type: Array, default: () => [] },
        theme: {
            default: 'transparent',
            validator(value) {
                return ['transparent', 'white'].includes(value);
            }
        }
    },
    data() {
        return {
            needTooltip: false,
            limitNavItemIndex: 0,
            userTypesDesktop: [
                { id: UserTypes.private, title: 'Частное лицо' },
                { id: UserTypes.company, title: 'Бизнес' }
            ],
            userTypesMobile: [
                { id: UserTypes.private, title: 'Физ', mobileTitle: 'Физ', icon: { name: 'user', width: 13, height: 12 } },
                { id: UserTypes.company, title: 'Бизнес', mobileTitle: 'Бизнес', icon: { name: 'bag', width: 14, height: 13 } }
            ]
        };
    },
    computed: {
        userType() {
            return userStore.userType;
        },
        userCity() {
            return userStore.userCity;
        },
        isAutoOpenCityTooltip() {
            return userStore.isShowDetectedCity;
        },
        logoItemTheme() {
            return this.theme === 'white' ? 'black' : 'white';
        },
        toggleGroupTheme() {
            return this.theme === 'white' ? 'simple' : 'base';
        },
        toggleTheme() {
            return this.theme === 'white' ? 'white' : 'base';
        },
        navItemsLength() {
            return this.navItems ? this.navItems.length : 0;
        },
        hiddenNavItems() {
            if (this.limitNavItemIndex) {
                return this.navItems.slice(this.limitNavItemIndex);
            }

            return this.navItems;
        },
        currentUserTypeNavItems() {
            return this.navItems.map(nav => ({
                ...nav,
                url: nav.url.replace(/userType=\d+/, `userType=${ this.userType }`)
            }));
        }
    },
    mounted() {
        window.addEventListener('resize', this.setNavItemsData);
        this.$nextTick(() => {
            this.setNavItemsData();
            this.$refs.navItemsWrapper.classList.remove('h-0');
        });

    },
    destroyed() {
        window.removeEventListener('resize', this.setNavItemsData);
    },
    methods: {
        getLimitNavItemIndex() {
            if (!this.$refs.navItemsWrapper || !this.$refs.navItem) { return 0; }

            const navItemsWrapperWidth = this.$refs.navItemsWrapper.clientWidth;
            const actionWidth = 36;
            const visibleWidth = navItemsWrapperWidth - actionWidth;
            let sumItemsVisibleWidth = 0;

            for (let i = 0; i < this.navItemsLength; i++) {
                sumItemsVisibleWidth += this.$refs.navItem[i].clientWidth;

                if (sumItemsVisibleWidth >= visibleWidth) {
                    return i;
                }
            }

            return 0;
        },
        setNavItemsData() {
            if (this.navItems) {
                this.limitNavItemIndex = this.getLimitNavItemIndex();
                this.needTooltip = this.limitNavItemIndex > 0;
            }
        }
    }
});
