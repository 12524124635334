//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allMetrics } from '~/mixins/metricsMixin';
export default {
    head() {
        return allMetrics();
    }
};
