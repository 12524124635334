import { Context } from '@nuxt/types';
import PageService from '~/services/Page';
import { pageStore } from '~/store';
import setUserIdentifierIfNotExists from '~/helpers/setUserIdentifierIfNotExists';
import StatisticsService from '~/services/Statistics';
import { STATISTIC_ACTIONS } from '~/constants/statistics';
import createFormData from '~/helpers/createFormData';

export default function({ route, app, req }: Context): Promise<void> {
    const pathOrName = route.name === 'all' ? route.path : route.name as string;

    if (process.server) {
        const host = req.headers['x-forwarded-proto'] + '://' + req.headers.host;
        pageStore.setHost(host);
    }

    if (!process.server) {
        const sessionId = setUserIdentifierIfNotExists(app.$cookies);
        StatisticsService.hit(createFormData({
            sessionId,
            action: STATISTIC_ACTIONS.PAGE_LOAD,
            page: route.fullPath
        }));
    } else {
        const sessionId = setUserIdentifierIfNotExists(app.$cookies);
        StatisticsService.hit({
            sessionId,
            action: STATISTIC_ACTIONS.PAGE_LOAD,
            page: route.fullPath
        });
    }

    return PageService.fetchData(pathOrName, { progress: false })
        .then(page => pageStore.update(page))
        .catch(() => pageStore.reset())
        .finally(() => {
            if (process.server) {
                pageStore.fix();
            }
        });
}
