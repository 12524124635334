export const USER_IDENTIFIER_COOKIE_NAME = '_usuuid';
export const USER_REFERRAL_COOKIE_NAME = 'referral';
export const USER_REFERRAL_QUERY_NAME = 'referralCode';

export enum USER_TYPE_SLUGS {
    COMPANY = 'business',
    PRIVATE = 'chastnoe'
}

export const USER_TYPE_ID_COOKIE_NAME = 'userType';
