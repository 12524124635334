import api, { RequestConfig } from '~/helpers/api';
import { CityDTO } from '~/models/Cities';
import { UserTypes } from '~/models/enums';


export default class UserService {
    /**
     * Получить город по IP
     * @param config Настройки запроса
     */
    static fetchUserCityByIP(config?: RequestConfig): Promise<CityDTO> {
        return api.get('/api/cities/ip', config);
    }

    static getUserType(userType: string|number): UserTypes {
        if (String(userType) === String(UserTypes.private)) {
            return UserTypes.private;
        }
        if (String(userType) === String(UserTypes.company)) {
            return UserTypes.company;
        }

        return UserTypes.private;
    }
}
