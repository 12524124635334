//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LogoIcon from '~/assets/sprite/svg/logo.svg?inline';
import getImage from '~/helpers/getImage';
import { UserTypes } from '~/models/enums';

const THEMES = {
    black: 'black',
    white: 'white'
};
const SIZES = {
    base: 'base',
    sm: 'sm'
};
export { SIZES, THEMES };

export default {
    components: { LogoIcon },
    props: {
        theme: {
            default: THEMES.black,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        size: {
            default: SIZES.base,
            validator(value) {
                return Object.keys(SIZES).includes(value);
            }
        },
        userType: {
            type: Number,
            default: UserTypes.private,
            validator(value) {
                return Object.values(UserTypes).includes(value);
            }
        }
    },
    data: () => ({
        userTypesEnum: UserTypes
    }),
    computed: {
        logoWhite() {
            return getImage('/images/logo/logo-white.png', 'rs:fit:308:308');
        },
        logoBlack() {
            return getImage('/images/logo/logo-black.png', 'rs:fit:308:308');
        }
    }
};
