import api, { RequestConfig } from '~/helpers/api';
import {
    CodeVerificationSuccessPostRequest,
    FormDataDTO,
    FormDataRequestDTO,
    FormDataSuccessRequest, UserDataTinkoffDTO
} from '~/models/Common';


export default class CommonService {
    /**
     * Получить данные формы
     * @param name Идентификатор формы
     * @param config Настройки запроса
     */
    static fetchFormData(name: string, config?: RequestConfig): Promise<FormDataDTO> {
        return api.get(`/api/forms/${ name }`, config);
    }

    /**
     * Отправить данные формы
     * @param name Идентификатор формы
     * @param formData данные
     * @param config Настройки запроса
     */
    static postFormData(name: string, formData: FormDataRequestDTO, config?: RequestConfig): Promise<FormDataSuccessRequest> {
        return api.post(`/api/forms/${ name }`, formData, config);
    }

    /**
     * Получить код верификации
     * @param scope группа (phone)
     * @param formData данные
     * @param config Настройки запроса
     */
    static postVerificationCode(scope: string, formData: { key: string }, config?: RequestConfig): Promise<CodeVerificationSuccessPostRequest> {
        return api.post(`/api/verificationCode/${ scope }`, formData, config);
    }

    /**
     * Подтвердить код верификации
     * @param scope группа (phone)
     * @param formData данные
     * @param config Настройки запроса
     */
    static putVerificationCode(scope: string, formData: { key: string }, config?: RequestConfig): Promise<CodeVerificationSuccessPostRequest> {
        return api.put(`/api/verificationCode/${ scope }`, formData, config);
    }

    /**
     * Получить url-адрес для авторизации Тинькофф
     * @param state уникальная строка
     * @param config Настройки запроса
     */
    static fetchTinkoffAuthUrl(state: string, config?: RequestConfig): Promise<{ url: string }> {
        return api.get('/api/partners/tinkoff/authUrl', {
            ...config,
            params: { ...config?.params, state }
        });
    }

    /**
     * Получить токен Тинькофф
     * @param code полученный код
     * @param config Настройки запроса
     */
    static fetchTinkoffToken(code: string, config?: RequestConfig): Promise<{ token: string }> {
        return api.get('/api/partners/tinkoff/token', {
            ...config,
            params: { ...config?.params, code }
        });
    }

    /**
     * Получить данные полльзователя по Тинькофф токену
     * @param token токен
     * @param config Настройки запроса
     */
    static fetchTinkoffUserData(token: string, config?: RequestConfig): Promise<UserDataTinkoffDTO> {
        return api.get('/api/partners/tinkoff/userData', {
            ...config,
            params: { ...config?.params, token }
        });
    }
}
