/**
 * @param imageUrl ссылка на оригинальную картинку
 * @param options настройки
 * @returns Ссылка на оптимизированную картинку
 */
import { ImageProp } from '~/models/Files';

export default function getImage(imageUrl: ImageProp|string|null|undefined, options: string): string {
    if (typeof imageUrl === 'object' && imageUrl) {
        imageUrl = imageUrl.file.url;
    }

    if (imageUrl) {
        imageUrl = imageUrl.match(/^https?:\/\//) ? imageUrl : ('local://' + imageUrl);
    } else {
        imageUrl = 'local://';
    }

    return [
        '/_image',
        'i',
        options,
        Buffer.from(imageUrl)
            .toString('base64')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '')
    ].join('/');
}
