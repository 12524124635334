import lazySizes from 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

Object.assign(lazySizes.cfg, {
    lazyClass: '-lazyload',
    loadedClass: '-lazyload-success',
    loadingClass: '-lazyload-loading',
    preloadClass: '-lazypreload',
    errorClass: '-lazyload-error',
    autosizesClass: '-lazyautosizes',
    srcAttr: 'data-src',
    srcsetAttr: 'data-srcset',
    sizesAttr: 'data-sizes',
    init: true
});

export default lazySizes;
