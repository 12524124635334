export function buildPageHead(state) {
    const facebookPixelId = process.env.FACEBOOK_PIXEL_ID;
    console.log('facebookPixelId', facebookPixelId);

    return {
        title: state.metaTitle || 'Мойкредит',
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { hid: 'msapplication-TileColor', name: 'msapplication-TileColor', content: '#da532c' },
            { hid: 'theme-color', name: 'theme-color', content: '#ffffff' },
            { name: 'google-site-verification', content: '9TzxkGMfrJo0AGTZAurTYRwtqeVtIzbU5hN50raz6cM' },
            ...state.metaInfo
            // ...meta
        ],
        link: [
            { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
            { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
            { rel: 'manifest', href: '/site.webmanifest' },
            { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' }
        ],
        script: [
            {
                once: true,
                hid: 'meta-pixel',
                innerHTML: `
                        !(function (f, b, e, v, n, t, s) {
                          if (f.fbq) return;
                          n = f.fbq = function () {
                            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                          };
                          if (!f._fbq) f._fbq = n;
                          n.push = n;
                          n.loaded = !0;
                          n.version = "2.0";
                          n.queue = [];
                          t = b.createElement(e);
                          t.async = !0;
                          t.src = v;
                          s = b.getElementsByTagName(e)[0];
                          s.parentNode.insertBefore(t, s);
                        })(
                          window,
                          document,
                          "script",
                          "https://connect.facebook.net/en_US/fbevents.js"
                        );
                        fbq("init", "${ facebookPixelId }");
                        fbq("track", "PageView");
                    `
            }
        ],
        noscript: [
            {
                hid: 'meta-pixel-noscript',
                innerHTML: `<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${ facebookPixelId }&ev=PageView&noscript=1" /></noscript>`
            }
        ],
        __dangerouslyDisableSanitizersByTagID: {
            'meta-pixel': ['innerHTML'],
            'meta-pixel-noscript': ['innerHTML']
        }
    };
}

export function buildPageHeadMetaInfo(pageData, host) {
    const data = pageData;
    if (!data) {
        return [];
    }

    return [
        { hid: 'description', name: 'description', content: data.meta?.description || '' },
        { hid: 'keywords', name: 'keywords', content: data.meta?.keywords || '' },
        { hid: 'og:title', name: 'og:title', content: data.meta?.title || '' },
        { hid: 'og:description', name: 'og:description', content: (data.meta?.description || '').replace(/<\/?[^>]+(>|$)/g, '') },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:url', property: 'og:url', content: data?.url || '' },
        { hid: 'og:image', property: 'og:image', content: (host || '') + '/mstile-310x310.png' },
        { hid: 'og:locale', property: 'og:locale', content: 'ru_RU' },
        { hid: 'og:site_name', property: 'og:site_name', content: 'МойКредит' }
    ];
}
