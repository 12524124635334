//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseIcon from '~/components/BaseIcon';
import LogoIcon from '~/assets/sprite/svg/logo-footer.svg?inline';
import makeClasses from '~/helpers/makeClasses';

const THEMES = {
    default: 'default',
    'blurred-light': 'blurred-light'
};

const useClasses = makeClasses(() => ({
    background: ({ theme }) => ['absolute inset-0 bg-black', {
        'md:bg-transparent': theme === THEMES.default,
        'md:bg-white md:bg-opacity-5 backdrop-filter backdrop-blur-[96px]': theme === THEMES['blurred-light']
    }],
    content: ({ theme }) => ['relative pt-8 pb-11 md:pb-3', {
        'text-white md:text-black': theme === THEMES.default,
        'text-white': theme === THEMES['blurred-light']
    }],
    containerTop: ({ theme }) => ['container pl-2 mb-[4.5rem] xl:mb-12 lg:pl-0', {
        'xl:mb-12': theme === THEMES.default,
        'xl:mb-12 md:mb-6': theme === THEMES['blurred-light']
    }],
    containerBottom: ({ theme }) => ['container px-2 flex items-center justify-between text-sm lg:px-0 md:flex-col md:items-start', {
        'md:pt-6 md:border-t md:border-white md:border-opacity-10': theme === THEMES['blurred-light']
    }],
    logo: ({ theme }) => ['flex-shrink-0 max-w-[202px] fill-current mr-14 xl:mb-4 xl:mr-0', {
        'text-white md:text-black': theme === THEMES.default,
        'text-white': theme === THEMES['blurred-light']
    }],
    mail: ({ theme }) => ['group flex items-center text-sm ml-6 xl:ml-0 xs:flex-wrap', {
        'text-white md:text-black': theme === THEMES.default,
        'text-white': theme === THEMES['blurred-light']
    }],
    socialItem: ({ theme }) => ['flex-shrink-0 flex items-center justify-center w-[2.375rem] h-[2.375rem] rounded-lg bg-white bg-opacity-10 fill-current text-white mx-1 transition-fast hover:bg-opacity-20', {
        'md:bg-transparent md:text-black md:border md:border-gray-700 md:border-opacity-10 md:hover:border-opacity-20': theme === THEMES.default,
        'md:bg-white md:bg-opacity-10': theme === THEMES['blurred-light']
    }]
}));

export default {
    storageItems: [
        'socials',
        'publicData',
        'links'
    ],
    components: { BaseIcon, LogoIcon },
    props: {
        navItems: { type: Array, default: () => [] },
        theme: {
            type: String,
            default: THEMES.default,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        }
    },
    computed: {
        filteredSocials() {
            return this.socials && this.socials.filter(social => social.url);
        },
        publicEmail() {
            return this.publicData && this.publicData.email;
        },
        copyrightText() {
            const title = (this.publicData && this.publicData.copyright) || 'Мойкредит';

            return `©${ title } ${ new Date().getFullYear() }`;
        },
        privacyPolicyLink() {
            return this.links && this.links.privacyPolicy;
        },
        classes() {
            return useClasses({
                theme: this.theme
            });
        }
    }
};
