var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{
        'relative z-[150]': true,
        'py-2 px-2 text-black': _vm.theme === 'white',
        'text-white': _vm.theme === 'transparent'
    }},[_c('div',{class:[_vm.theme === 'white' ? 'absolute inset-2 rounded-base shadow-md bg-white' : '']}),_vm._v(" "),_c('div',{class:[
            'relative flex items-center container',
            _vm.theme === 'white' ? 'py-4 px-2 lg:px-0 lg:py-3' : 'pt-8 pb-6 px-2.5 lg:py-4 lg:px-0'
        ]},[_c('div',{staticClass:"flex lg:flex-1 lg:gap-2"},[_c('button',{staticClass:"a-lg:hidden w-[26px] h-[20px]",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$layer.open('BurgerLayer', { navItems: _vm.navItems })}}},[_c('span',{staticClass:"fill-current transition-fast hover:opacity-80"},[_c('BaseIcon',{attrs:{"name":"burger","width":"26","height":"20"}})],1)]),_vm._v(" "),_c('LogoItem',{class:['mr-6 lg:mx-1', _vm.theme === 'white' ? 'md:mr-2' : 'md:mr-3'],attrs:{"user-type":_vm.userType,"theme":_vm.logoItemTheme}}),_vm._v(" "),_c('UserTypeToggle',{staticClass:"mr-7 lg:mr-3 flex-shrink-0 lg:hidden",attrs:{"user-types":_vm.userTypesDesktop,"theme":_vm.toggleGroupTheme,"toggle-theme":_vm.toggleTheme,"font-theme":"md"}})],1),_vm._v(" "),_c('UserTypeToggle',{staticClass:"flex-shrink-0 a-lg:hidden lg:-mr-4",attrs:{"user-types":_vm.userTypesMobile,"theme":_vm.toggleGroupTheme,"toggle-theme":_vm.toggleTheme,"hide-active-title":true,"call-to-action":true,"toggle-view":"circle","font-theme":"md","size":"sm"}}),_vm._v(" "),_c('div',{ref:"navItemsWrapper",staticClass:"relative flex-auto h-0 overflow-hidden lg:hidden"},[(_vm.currentUserTypeNavItems.length)?_c('div',{staticClass:"relative flex items-center -mx-4"},[_vm._l((_vm.currentUserTypeNavItems),function(navItem,navItemIndex){return _c('div',{key:navItem.id,ref:"navItem",refInFor:true,class:['px-4 flex-shrink-0', {
                        'absolute opacity-0 pointer-events-none': _vm.needTooltip && navItemIndex >= _vm.limitNavItemIndex
                    }]},[_c('NuxtLink',{staticClass:"font-normal transition-main hover:opacity-80",attrs:{"to":navItem.url}},[_vm._v("\n                        "+_vm._s(navItem.title)+"\n                    ")])],1)}),_vm._v(" "),(_vm.needTooltip)?_c('BaseTooltip',{attrs:{"distance":10,"trigger":"click"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('span',{class:['fill-current cursor-pointer p-2 flex items-center justify-center rounded transition-fast hover:opacity-80', {
                                'backdrop-filter backdrop-blur-lg': _vm.theme === 'transparent',
                                'hover:bg-gray-50': _vm.theme === 'white'
                            }]},[_c('BaseIcon',{attrs:{"name":"dots","width":"20","height":"4"}})],1)]},proxy:true}],null,false,1398750861)},[_vm._v(" "),(_vm.hiddenNavItems)?_c('div',{staticClass:"py-1.5 px-2.5 text-left"},_vm._l((_vm.hiddenNavItems),function(navItem){return _c('div',{key:navItem.id,ref:"navItem",refInFor:true,staticClass:"mb-0.5 last:mb-0"},[_c('NuxtLink',{staticClass:"font-normal transition-main hover:opacity-70",attrs:{"to":navItem.url}},[_vm._v("\n                                "+_vm._s(navItem.title)+"\n                            ")])],1)}),0):_vm._e()]):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('CityTooltip',{staticClass:"lg:w-4 lg:invisible",attrs:{"city":_vm.userCity,"auto-open":_vm.isAutoOpenCityTooltip,"theme":_vm.theme}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }