import merge from 'lodash/mergeWith';

const YM_MAIN_ID = process.env.yaMetricsMainId;

export function ym() {
    if (!YM_MAIN_ID) {
        return {};
    }

    return {
        script: [
            {
                hid: 'ym-init',
                innerHTML: `
                    if (!window.YM_INITED) {
                       (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                       m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                       (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                        ym(${ YM_MAIN_ID || '' }, "init", {
                            clickmap:true,
                            trackLinks:true,
                            accurateTrackBounce:true,
                            webvisor:true
                        });
                        window.YM_IDS = [${ YM_MAIN_ID }];
                        window.YM_INITED = true;
                    }
                `,
                body: true
            }
        ],
        __dangerouslyDisableSanitizersByTagID: {
            'ym-init': ['innerHTML']
        }
    };
}

export function allMetrics() {
    if (process.env.NODE_ENV !== 'production') {
        return {};
    }

    return merge(
        ym(),
        (value, newValue) => {
            if (Array.isArray(value)) {
                return value.concat(newValue);
            }
        }
    );
}

const metricsMixin = {
};

export default metricsMixin;
