
import { makeTextPretty } from '@/helpers/typograph';

export default {
    props: {
        html: { type: String, required: true },
        as: { type: String, required: false, default: 'div' }
    },
    render(createElement) {
        if (this.html) {
            return createElement(this.as, {
                class: 'typography',
                domProps: {
                    innerHTML: makeTextPretty(`${ this.html }`)
                }
            });
        }

        return createElement(this.as);
    }
};
