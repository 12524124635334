//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import makeClasses from '~/helpers/makeClasses';
import CircleLoading from '~/components/CircleLoading';

const THEMES = {
    base: 'base',
    black: 'black',
    black_outlined: 'black_outlined',
    gray: 'gray',
    transparent_blue: 'transparent_blue',
    transparent_light: 'transparent_light',
    blue_outlined_always: 'blue_outlined_always',
    global: 'global'
};
const VIEWS = {
    base: 'base',
    md: 'md',
    sm: 'sm',
    rounded: 'rounded',
    link: 'link',
    simple: 'simple',
    base_consistent: 'base_consistent',
    outlined: 'outlined'
};

const SIZES = {
    DEFAULT: 'DEFAULT',
    GAP_X_SM: 'GAP_X_SM'
};

export { THEMES, VIEWS, SIZES };

const useClasses = makeClasses(() => ({
    root: ({ theme, view, loading, disabled, size }) => ({
        'pointer-events-none': loading || disabled,
        'opacity-60': disabled,
        'relative inline-block transition-all duration-300 ease-in-out': true,
        'bg-blue text-white hover:bg-blue-450': theme === THEMES.base,
        'bg-black text-white hover:bg-gray-800': theme === THEMES.black && view !== VIEWS.outlined,
        'text-black before:absolute before:inset-0 before:block before:border before:rounded-inherit before:transition before:ease-in-out ': view === VIEWS.outlined,
        'before:border-black mouse-device:hover:text-gray-800 mouse-device:hover:before:border-gray-650 mouse-device:active:before:border-gray-650 mouse-device:focus:before:border-gray-650': theme === THEMES.black && view === VIEWS.outlined,
        'bg-gray-650 text-white hover:bg-black': theme === THEMES.gray,
        'text-blue hover:text-blue-600': theme === THEMES.transparent_blue,
        'text-white before:absolute before:inset-0 before:block before:border before:border-white before:rounded-inherit before:transition before:ease-in-out hover:bg-blue hover:text-white hover:before:opacity-0': theme === THEMES.transparent_light,
        'text-blue before:absolute before:inset-0 before:block before:border before:border-blue before:rounded-inherit before:transition before:ease-in-out mouse-device:hover:opacity-80': theme === THEMES.blue_outlined_always,
        'active:shadow-md focus:shadow-md': theme === THEMES.base && view === VIEWS.base,
        'text-primary before:absolute before:inset-0 before:block before:border before:border-primary before:rounded-inherit': theme === THEMES.global && view === VIEWS.outlined,
        'py-2.5 rounded-lg': view === VIEWS.base || view === VIEWS.outlined || view === VIEWS.base_consistent,
        'xs:px-4': (view === VIEWS.base || view === VIEWS.outlined) && size === SIZES.DEFAULT,
        'rounded-full w-[64px] h-[64px]': view === VIEWS.rounded,
        'px-6': (view === VIEWS.base || view === VIEWS.outlined || view === VIEWS.base_consistent) && size === SIZES.DEFAULT,
        'px-2 xs:px-2': size === SIZES.GAP_X_SM,
        flex: view === VIEWS.link,
        'rounded-lg': view === VIEWS.simple,
        'py-2.5 px-8 rounded-lg xs:px-4': view === VIEWS.md
    }),
    content: () => 'relative flex items-center justify-center -mx-1',
    sides: ({ loading }) => ({
        'mx-1 fill-current flex-shrink-0': true,
        'opacity-0': loading
    }),
    prepend: () => ({}),
    append: () => ({}),
    text: ({ loading }) => ({
        'mx-1': true,
        'opacity-0': loading
    }),
    loading: () => 'absolute inset-0 flex items-center justify-center'
}));

export default {
    components: { CircleLoading },
    props: {
        href: { type: [String, Object] },
        disabled: { type: Boolean, default: false },
        type: {
            default: 'button',
            validator(value) {
                return ['button', 'submit'].includes(value);
            }
        },
        loading: { type: Boolean, default: false },
        theme: {
            default: THEMES.base,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        view: {
            default: 'base',
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        size: {
            default: SIZES.DEFAULT,
            validator(value) {
                return Object.keys(SIZES).includes(value);
            }
        }
    },
    computed: {
        buttonAttrs() {
            const attrs = {
                disabled: this.disabled
            };

            if (this.componentName === 'button') {
                Object.assign(attrs, {
                    type: this.type
                });

            } else if (this.componentName === 'NuxtLink') {
                Object.assign(attrs, {
                    to: this.href
                });

            } else if (this.componentName === 'a') {
                Object.assign(attrs, {
                    href: this.href,
                    target: '_blank'
                });
            }

            return attrs;
        },
        componentName() {
            if (typeof this.href === 'object' || (typeof this.href === 'string' && /^\//.test(this.href))) {
                return 'NuxtLink';
            } else if (typeof this.href === 'string' && !/^\//.test(this.href)) {
                return 'a';
            }

            return 'button';
        },
        classes() {
            return useClasses({
                theme: this.theme,
                view: this.view,
                loading: this.loading,
                disabled: this.disabled,
                size: this.size
            });
        },
        circleLoadingTheme() {
            return this.theme === THEMES.black_outlined ? 'black' : 'white';
        }
    }
};
