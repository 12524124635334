import { Context } from '@nuxt/types';
import setUserIdentifierIfNotExists from '~/helpers/setUserIdentifierIfNotExists';
import StatisticsService from '~/services/Statistics';
import createFormData from '~/helpers/createFormData';
import { STATISTIC_ACTIONS } from '~/constants/statistics';
import { USER_REFERRAL_COOKIE_NAME, USER_REFERRAL_QUERY_NAME } from '~/constants/user';

export default function({ app, route }: Context): void {
    /**
     * При первом заходе на страницу вызывается, так как middleware не вызывается при первом заходе
     */
    if (process.client) {
        StatisticsService.hit(createFormData({
            sessionId: setUserIdentifierIfNotExists(app.$cookies),
            action: STATISTIC_ACTIONS.PAGE_LOAD,
            page: route.fullPath
        }));
    }
    if (route.query[USER_REFERRAL_QUERY_NAME]) {
        app.$cookies.set(USER_REFERRAL_COOKIE_NAME, route.query[USER_REFERRAL_QUERY_NAME]);
    }
}
