export default function(to, from, savedPosition) {
    if (to.hash) {
        return {
            selector: to.hash,
            behavior: 'smooth'
        };
        // для всех страниц, кроме offers
    } else if (to.name !== 'offers') {
        return { x: 0, y: 0, behavior: 'smooth' };
    }
}
