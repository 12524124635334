import { Module, VuexMutation, VuexModule, VuexAction } from 'nuxt-property-decorator';
import UserService from '~/services/User';
import { CityDTO } from '~/models/Cities';
import { UserTypes } from '~/models/enums';
import CityService from '~/services/City';

interface UserState {
    _userType: UserTypes;
    _userCity: CityDTO | null;
    _isShowDetectedCity: boolean;
}

@Module({
    name: 'user',
    stateFactory: true,
    namespaced: true
})
export default class UserStore extends VuexModule {
    private _userType: UserState['_userType'] = 1;
    private _userCity: UserState['_userCity'] = null;
    private _isShowDetectedCity: UserState['_isShowDetectedCity'] = false;

    // UserType
    @VuexMutation
    private _updateUserType(payload: UserState['_userType']): void {
        this._userType = payload;
    }

    @VuexAction
    updateUserType(payload: UserState['_userType']): void {
        this._updateUserType(payload);
    }

    get userType(): UserState['_userType'] {
        return this._userType;
    }

    // UserCity
    @VuexMutation
    private _updateUserCity(payload: UserState['_userCity']): void {
        this._userCity = payload;
    }

    @VuexAction
    updateUserCity(payload: UserState['_userCity']): void {
        this._updateUserCity(payload);
    }

    @VuexAction
    async fetchUserCityByIP(): Promise<void> {
        try {
            const userCity = await UserService.fetchUserCityByIP();
            if (userCity) { this._updateUserCity(userCity); }
        } catch (err) {
            console.error(err);
        }
    }

    @VuexAction
    async setCityIfExists(cityId: string|number): Promise<void> {
        const [userCity] = await CityService.fetchById(cityId, { progress: false });
        if (userCity) {
            this._updateUserCity(userCity);
        }
    }

    get userCity(): UserState['_userCity'] {
        return this._userCity;
    }

    // IsShowDetectedCity
    @VuexMutation
    private _updateIsShowDetectedCity(payload: UserState['_isShowDetectedCity']): void {
        this._isShowDetectedCity = payload;
    }

    @VuexAction
    updateIsShowDetectedCity(payload: UserState['_isShowDetectedCity']): void {
        this._updateIsShowDetectedCity(payload);
    }

    get isShowDetectedCity(): UserState['_isShowDetectedCity'] {
        return this._isShowDetectedCity;
    }
}
