var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.city)?_c('BaseTooltip',{attrs:{"theme":"light","placement":"bottom-end","distance":16,"arrow":false,"trigger":"click","auto-open":_vm.autoOpen,"z-index":_vm.tooltipZIndex},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('button',{class:[
                'justify-self-end ml-auto flex items-center group flex-shrink-0 lg:w-4 lg:ml-0 lg:transition-fast lg:hover:opacity-80',
                _vm.theme === 'white'? 'md:mr-2' : 'md:mr-0.5'
            ],attrs:{"type":"button"}},[_c('span',{staticClass:"fill-current"},[_c('BaseIcon',{attrs:{"name":"geo-mark","width":"16","height":"16"}})],1),_vm._v(" "),_c('span',{class:{
                    'ml-1 font-bold transition-main group-hover:opacity-80': true,
                    'lg:hidden': !_vm.alwaysShowCity
                }},[_vm._v("\n                "+_vm._s(_vm.city.title)+"\n            ")])])]},proxy:true},{key:"default",fn:function(ref){
                var actions = ref.actions;
return [_c('div',{staticClass:"p-6"},[_c('div',{staticClass:"text-sm text-gray-650 mb-0.5"},[_vm._v("\n                Ваш город\n            ")]),_vm._v(" "),_c('div',{staticClass:"title-h5 mb-5"},[_vm._v("\n                "+_vm._s(_vm.city.title)+"?\n            ")]),_vm._v(" "),_c('div',{staticClass:"flex items-center -mx-1.5"},[_c('div',{staticClass:"flex-initial px-1.5"},[_c('BaseButton',{staticClass:"w-[180px]",attrs:{"theme":"gray"},nativeOn:{"click":function($event){$event.preventDefault();return (function () {
                            actions.hide();
                            _vm.$layer.open('CityConfirm')
                        })($event)}}},[_vm._v("\n                        Другой город\n                    ")])],1),_vm._v(" "),_c('div',{staticClass:"flex-initial px-1.5"},[_c('BaseButton',{nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleCityConfirm(actions.hide)}}},[_vm._v("\n                        Да\n                    ")])],1)])])]}}],null,false,392658257)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }