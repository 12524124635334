//
//
//
//
//
//

import CircleLoading from '@/components/CircleLoading';

export default {
    components: {
        CircleLoading
    },
    data: () => ({
        loading: false
    }),
    methods: {
        start() {
            this.loading = true;
        },
        finish() {
            this.loading = false;
        }
    }
};
