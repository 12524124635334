export default {
    data() {
        return {
            documentSize: {
                width: 0,
                height: 0
            },
            windowSize: {
                width: 0,
                height: 0
            }
        };
    },
    methods: {
        handleResizeWindow() {
            if (typeof window === 'undefined') { return; }

            this.documentSize.width = +document.documentElement.clientWidth;
            this.documentSize.height = +document.documentElement.clientHeight;

            this.windowSize.width = +window.innerWidth;
            this.windowSize.height = +window.innerHeight;
        }
    },
    mounted() {
        this.handleResizeWindow();
        window.addEventListener('resize', this.handleResizeWindow);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResizeWindow);
    }
};
