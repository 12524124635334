//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '@/components/BaseButton';
import { layoutStore } from '@/store';
import { allMetrics } from '@/mixins/metricsMixin';


export default {
    components: { BaseButton },
    props: {
        error: { type: Object }
    },
    head() {
        return {
            ...allMetrics(),
            title: 'Ошибка'
        };
    },
    computed: {
        errorMessage() {
            const defaultMessage = 'Произошла ошибка';

            if (this.$store.app.context.isDev) {
                return this.error.message || defaultMessage;
            }

            return defaultMessage;
        }
    },
    mounted() {
        layoutStore.updateHeaderTheme('white');
    },
    methods: {
        reload() {
            location.reload();
        }
    }
};
