/* eslint-disable import/first */
/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex';
import { getModule } from 'nuxt-property-decorator';


// Убирает сервисные свойства, чтобы работа делалась через публичные методы интерфейса
type PublicModule<M> = Omit<M, 'modules' | 'state' | 'getters' | 'mutations' | 'namespaced' | 'actions' | 'context'>;

// Каждый стор необходимо указывать с типом
// Объявляем переменную и сразу экспортируем её. После будем импортировать стор отсюда.
// Не присваивайте значение здесь! Это делается в initStore.
import { Context } from '@nuxt/types';
import PageStore from '~/store/page';
export let pageStore: PublicModule<PageStore>;

import UserStore from '~/store/user';
export let userStore: PublicModule<UserStore>;

import LayoutStore from '~/store/layout';
export let layoutStore: PublicModule<LayoutStore>;

import CommonStore from '~/store/common';
export let commonStore: PublicModule<CommonStore>;

import { UserTypes } from '~/models/enums';

export const initStore = (store: Store<any>): void => {
    // Для каждого стора, нужно написать получение модуля.
    // Просто копируйте строку, подменяйте названия и всё будет хорошо :-)
    pageStore = getModule(PageStore, store);
    userStore = getModule(UserStore, store);
    layoutStore = getModule(LayoutStore, store);
    commonStore = getModule(CommonStore, store);
};

/**
 * nuxtServerInit implementation
 * can also use (store: Store<any>, context: Context)
 */
export const actions = {
    async nuxtServerInit(_store: Store<any>, { app }: Context): Promise<void> {
        const userType = app.$cookies.get('userType') || UserTypes.private;
        const userCity = app.$cookies.get('userCity') || null;
        userStore.updateUserType(userType);
        if (userCity) {
            userStore.updateUserCity(userCity);
            userStore.updateIsShowDetectedCity(false);
        } else {
            await userStore.fetchUserCityByIP();
            userStore.updateIsShowDetectedCity(true);
        }

        // Общие данные: меню, настройки и пр.
        await Promise.all([
            await commonStore.fetchHeaderMenu(),
            await commonStore.fetchFooterMenu()
        ]);
    }
};
