import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  DelayHydration: () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c)),
  HydrationStatus: () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c)),
  AddReviewLayer: () => import('../../components/layers/AddReviewLayer.vue' /* webpackChunkName: "components/add-review-layer" */).then(c => wrapFunctional(c.default || c)),
  AlertLayer: () => import('../../components/layers/AlertLayer.vue' /* webpackChunkName: "components/alert-layer" */).then(c => wrapFunctional(c.default || c)),
  ApplicationLayer: () => import('../../components/layers/ApplicationLayer.vue' /* webpackChunkName: "components/application-layer" */).then(c => wrapFunctional(c.default || c)),
  ApplicationLeaveLayer: () => import('../../components/layers/ApplicationLeaveLayer.vue' /* webpackChunkName: "components/application-leave-layer" */).then(c => wrapFunctional(c.default || c)),
  BaseLayer: () => import('../../components/layers/BaseLayer.vue' /* webpackChunkName: "components/base-layer" */).then(c => wrapFunctional(c.default || c)),
  BurgerLayer: () => import('../../components/layers/BurgerLayer.vue' /* webpackChunkName: "components/burger-layer" */).then(c => wrapFunctional(c.default || c)),
  CityConfirm: () => import('../../components/layers/CityConfirm.vue' /* webpackChunkName: "components/city-confirm" */).then(c => wrapFunctional(c.default || c)),
  CloseTabLayer: () => import('../../components/layers/CloseTabLayer.vue' /* webpackChunkName: "components/close-tab-layer" */).then(c => wrapFunctional(c.default || c)),
  ConfirmLayer: () => import('../../components/layers/ConfirmLayer.vue' /* webpackChunkName: "components/confirm-layer" */).then(c => wrapFunctional(c.default || c)),
  ConsultLayer: () => import('../../components/layers/ConsultLayer.vue' /* webpackChunkName: "components/consult-layer" */).then(c => wrapFunctional(c.default || c)),
  FeedbackLayer: () => import('../../components/layers/FeedbackLayer.vue' /* webpackChunkName: "components/feedback-layer" */).then(c => wrapFunctional(c.default || c)),
  NewsCardDetailLayer: () => import('../../components/layers/NewsCardDetailLayer.vue' /* webpackChunkName: "components/news-card-detail-layer" */).then(c => wrapFunctional(c.default || c)),
  NewsCardLayer: () => import('../../components/layers/NewsCardLayer.vue' /* webpackChunkName: "components/news-card-layer" */).then(c => wrapFunctional(c.default || c)),
  NewsChannelLayer: () => import('../../components/layers/NewsChannelLayer.vue' /* webpackChunkName: "components/news-channel-layer" */).then(c => wrapFunctional(c.default || c)),
  OfferDetailLayer: () => import('../../components/layers/OfferDetailLayer.vue' /* webpackChunkName: "components/offer-detail-layer" */).then(c => wrapFunctional(c.default || c)),
  PlaceAdLayer: () => import('../../components/layers/PlaceAdLayer.vue' /* webpackChunkName: "components/place-ad-layer" */).then(c => wrapFunctional(c.default || c)),
  ReviewsLayer: () => import('../../components/layers/ReviewsLayer.vue' /* webpackChunkName: "components/reviews-layer" */).then(c => wrapFunctional(c.default || c)),
  ScoringNotificationLayer: () => import('../../components/layers/ScoringNotificationLayer.vue' /* webpackChunkName: "components/scoring-notification-layer" */).then(c => wrapFunctional(c.default || c)),
  SelectLayer: () => import('../../components/layers/SelectLayer.vue' /* webpackChunkName: "components/select-layer" */).then(c => wrapFunctional(c.default || c)),
  AdBanner: () => import('../../components/AdBanner.vue' /* webpackChunkName: "components/ad-banner" */).then(c => wrapFunctional(c.default || c)),
  AdBannerController: () => import('../../components/AdBannerController.vue' /* webpackChunkName: "components/ad-banner-controller" */).then(c => wrapFunctional(c.default || c)),
  BaseButton: () => import('../../components/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c)),
  BaseIcon: () => import('../../components/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c)),
  BaseImage: () => import('../../components/BaseImage.vue' /* webpackChunkName: "components/base-image" */).then(c => wrapFunctional(c.default || c)),
  BaseNotification: () => import('../../components/BaseNotification.vue' /* webpackChunkName: "components/base-notification" */).then(c => wrapFunctional(c.default || c)),
  BasePagination: () => import('../../components/BasePagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c)),
  BaseSlider: () => import('../../components/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c)),
  BaseTooltip: () => import('../../components/BaseTooltip.vue' /* webpackChunkName: "components/base-tooltip" */).then(c => wrapFunctional(c.default || c)),
  BaseTooltipTag: () => import('../../components/BaseTooltipTag.vue' /* webpackChunkName: "components/base-tooltip-tag" */).then(c => wrapFunctional(c.default || c)),
  Breadcrumbs: () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c)),
  CalculatorLogic: () => import('../../components/CalculatorLogic.vue' /* webpackChunkName: "components/calculator-logic" */).then(c => wrapFunctional(c.default || c)),
  ChatCarrotQuest: () => import('../../components/ChatCarrotQuest.vue' /* webpackChunkName: "components/chat-carrot-quest" */).then(c => wrapFunctional(c.default || c)),
  CheckItem: () => import('../../components/CheckItem.vue' /* webpackChunkName: "components/check-item" */).then(c => wrapFunctional(c.default || c)),
  CircleLoading: () => import('../../components/CircleLoading.vue' /* webpackChunkName: "components/circle-loading" */).then(c => wrapFunctional(c.default || c)),
  CityTooltip: () => import('../../components/CityTooltip.vue' /* webpackChunkName: "components/city-tooltip" */).then(c => wrapFunctional(c.default || c)),
  CookieAlert: () => import('../../components/CookieAlert.vue' /* webpackChunkName: "components/cookie-alert" */).then(c => wrapFunctional(c.default || c)),
  CountdownTimer: () => import('../../components/CountdownTimer.vue' /* webpackChunkName: "components/countdown-timer" */).then(c => wrapFunctional(c.default || c)),
  CreditBoard: () => import('../../components/CreditBoard.vue' /* webpackChunkName: "components/credit-board" */).then(c => wrapFunctional(c.default || c)),
  CreditBoardExtensiveField: () => import('../../components/CreditBoardExtensiveField.vue' /* webpackChunkName: "components/credit-board-extensive-field" */).then(c => wrapFunctional(c.default || c)),
  DetailTable: () => import('../../components/DetailTable.vue' /* webpackChunkName: "components/detail-table" */).then(c => wrapFunctional(c.default || c)),
  ExternalWidget: () => import('../../components/ExternalWidget.vue' /* webpackChunkName: "components/external-widget" */).then(c => wrapFunctional(c.default || c)),
  FeedbackForm: () => import('../../components/FeedbackForm.vue' /* webpackChunkName: "components/feedback-form" */).then(c => wrapFunctional(c.default || c)),
  InfiniteSlideBar: () => import('../../components/InfiniteSlideBar.vue' /* webpackChunkName: "components/infinite-slide-bar" */).then(c => wrapFunctional(c.default || c)),
  InlineFeedbackForm: () => import('../../components/InlineFeedbackForm.vue' /* webpackChunkName: "components/inline-feedback-form" */).then(c => wrapFunctional(c.default || c)),
  IntersectionObserver: () => import('../../components/IntersectionObserver.vue' /* webpackChunkName: "components/intersection-observer" */).then(c => wrapFunctional(c.default || c)),
  LoadingBar: () => import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c)),
  LogoItem: () => import('../../components/LogoItem.vue' /* webpackChunkName: "components/logo-item" */).then(c => wrapFunctional(c.default || c)),
  MediaVideo: () => import('../../components/MediaVideo.vue' /* webpackChunkName: "components/media-video" */).then(c => wrapFunctional(c.default || c)),
  NewsCard: () => import('../../components/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c)),
  NewsCatalog: () => import('../../components/NewsCatalog.vue' /* webpackChunkName: "components/news-catalog" */).then(c => wrapFunctional(c.default || c)),
  NewsItem: () => import('../../components/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c)),
  OffersCarousel: () => import('../../components/OffersCarousel.vue' /* webpackChunkName: "components/offers-carousel" */).then(c => wrapFunctional(c.default || c)),
  PageMetaInfo: () => import('../../components/PageMetaInfo.vue' /* webpackChunkName: "components/page-meta-info" */).then(c => wrapFunctional(c.default || c)),
  PageSocialBlock: () => import('../../components/PageSocialBlock.vue' /* webpackChunkName: "components/page-social-block" */).then(c => wrapFunctional(c.default || c)),
  PhoneConfirm: () => import('../../components/PhoneConfirm.vue' /* webpackChunkName: "components/phone-confirm" */).then(c => wrapFunctional(c.default || c)),
  PromoAdvantages: () => import('../../components/PromoAdvantages.vue' /* webpackChunkName: "components/promo-advantages" */).then(c => wrapFunctional(c.default || c)),
  PromoBlock: () => import('../../components/PromoBlock.vue' /* webpackChunkName: "components/promo-block" */).then(c => wrapFunctional(c.default || c)),
  QuoteItem: () => import('../../components/QuoteItem.vue' /* webpackChunkName: "components/quote-item" */).then(c => wrapFunctional(c.default || c)),
  ReviewCard: () => import('../../components/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c)),
  ScrollableContainer: () => import('../../components/ScrollableContainer.vue' /* webpackChunkName: "components/scrollable-container" */).then(c => wrapFunctional(c.default || c)),
  SearchInformation: () => import('../../components/SearchInformation.vue' /* webpackChunkName: "components/search-information" */).then(c => wrapFunctional(c.default || c)),
  SearchSuggestionField: () => import('../../components/SearchSuggestionField.vue' /* webpackChunkName: "components/search-suggestion-field" */).then(c => wrapFunctional(c.default || c)),
  SelectLayerButton: () => import('../../components/SelectLayerButton.vue' /* webpackChunkName: "components/select-layer-button" */).then(c => wrapFunctional(c.default || c)),
  SeoContent: () => import('../../components/SeoContent.vue' /* webpackChunkName: "components/seo-content" */).then(c => wrapFunctional(c.default || c)),
  ServiceAdvantages: () => import('../../components/ServiceAdvantages.vue' /* webpackChunkName: "components/service-advantages" */).then(c => wrapFunctional(c.default || c)),
  SortOffers: () => import('../../components/SortOffers.vue' /* webpackChunkName: "components/sort-offers" */).then(c => wrapFunctional(c.default || c)),
  SuggestionField: () => import('../../components/SuggestionField.vue' /* webpackChunkName: "components/suggestion-field" */).then(c => wrapFunctional(c.default || c)),
  TagsInfiniteSlideBar: () => import('../../components/TagsInfiniteSlideBar.vue' /* webpackChunkName: "components/tags-infinite-slide-bar" */).then(c => wrapFunctional(c.default || c)),
  TheFooter: () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c)),
  TheHeader: () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c)),
  Typograph: () => import('../../components/Typograph.vue' /* webpackChunkName: "components/typograph" */).then(c => wrapFunctional(c.default || c)),
  UploadedFile: () => import('../../components/UploadedFile.vue' /* webpackChunkName: "components/uploaded-file" */).then(c => wrapFunctional(c.default || c)),
  UsefulInformation: () => import('../../components/UsefulInformation.vue' /* webpackChunkName: "components/useful-information" */).then(c => wrapFunctional(c.default || c)),
  UserAvatar: () => import('../../components/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c)),
  UserCard: () => import('../../components/UserCard.vue' /* webpackChunkName: "components/user-card" */).then(c => wrapFunctional(c.default || c)),
  UserTypeToggle: () => import('../../components/UserTypeToggle.vue' /* webpackChunkName: "components/user-type-toggle" */).then(c => wrapFunctional(c.default || c)),
  VendorReviews: () => import('../../components/VendorReviews.vue' /* webpackChunkName: "components/vendor-reviews" */).then(c => wrapFunctional(c.default || c)),
  AnimatedFigure: () => import('../../components/AnimatedFigures/AnimatedFigure.vue' /* webpackChunkName: "components/animated-figure" */).then(c => wrapFunctional(c.default || c)),
  AnimatedFiguresBackground: () => import('../../components/AnimatedFigures/AnimatedFiguresBackground.vue' /* webpackChunkName: "components/animated-figures-background" */).then(c => wrapFunctional(c.default || c)),
  AnimatedPromoArrow: () => import('../../components/AnimatedFigures/AnimatedPromoArrow.vue' /* webpackChunkName: "components/animated-promo-arrow" */).then(c => wrapFunctional(c.default || c)),
  ApplicationFormConstructorField: () => import('../../components/ApplicationForm/ConstructorField.vue' /* webpackChunkName: "components/application-form-constructor-field" */).then(c => wrapFunctional(c.default || c)),
  ApplicationFormProgress: () => import('../../components/ApplicationForm/FormProgress.vue' /* webpackChunkName: "components/application-form-progress" */).then(c => wrapFunctional(c.default || c)),
  ApplicationReviewBaseApplicationFormField: () => import('../../components/ApplicationReview/BaseApplicationFormField.vue' /* webpackChunkName: "components/application-review-base-application-form-field" */).then(c => wrapFunctional(c.default || c)),
  ApplicationReviewBaseSection: () => import('../../components/ApplicationReview/BaseSection.vue' /* webpackChunkName: "components/application-review-base-section" */).then(c => wrapFunctional(c.default || c)),
  ApplicationReviewContractSection: () => import('../../components/ApplicationReview/ContractSection.vue' /* webpackChunkName: "components/application-review-contract-section" */).then(c => wrapFunctional(c.default || c)),
  ApplicationReviewLoanInfoSectionFields: () => import('../../components/ApplicationReview/LoanInfoSectionFields.vue' /* webpackChunkName: "components/application-review-loan-info-section-fields" */).then(c => wrapFunctional(c.default || c)),
  ApplicationReviewPersonalInfoSectionFields: () => import('../../components/ApplicationReview/PersonalInfoSectionFields.vue' /* webpackChunkName: "components/application-review-personal-info-section-fields" */).then(c => wrapFunctional(c.default || c)),
  CardStackBaseCardStack: () => import('../../components/CardStack/BaseCardStack.vue' /* webpackChunkName: "components/card-stack-base-card-stack" */).then(c => wrapFunctional(c.default || c)),
  CardStack: () => import('../../components/CardStack/CardStack.vue' /* webpackChunkName: "components/card-stack" */).then(c => wrapFunctional(c.default || c)),
  CardStackFaqCardStack: () => import('../../components/CardStack/FaqCardStack.vue' /* webpackChunkName: "components/card-stack-faq-card-stack" */).then(c => wrapFunctional(c.default || c)),
  CardStackWidgetCardStack: () => import('../../components/CardStack/WidgetCardStack.vue' /* webpackChunkName: "components/card-stack-widget-card-stack" */).then(c => wrapFunctional(c.default || c)),
  ContentAllBanks: () => import('../../components/Content/ContentAllBanks.vue' /* webpackChunkName: "components/content-all-banks" */).then(c => wrapFunctional(c.default || c)),
  ContentBlocks: () => import('../../components/Content/ContentBlocks.vue' /* webpackChunkName: "components/content-blocks" */).then(c => wrapFunctional(c.default || c)),
  ContentClues: () => import('../../components/Content/ContentClues.vue' /* webpackChunkName: "components/content-clues" */).then(c => wrapFunctional(c.default || c)),
  ContentCreditProducts: () => import('../../components/Content/ContentCreditProducts.vue' /* webpackChunkName: "components/content-credit-products" */).then(c => wrapFunctional(c.default || c)),
  ContentFAQ: () => import('../../components/Content/ContentFAQ.vue' /* webpackChunkName: "components/content-f-a-q" */).then(c => wrapFunctional(c.default || c)),
  ContentFeedbackForm: () => import('../../components/Content/ContentFeedbackForm.vue' /* webpackChunkName: "components/content-feedback-form" */).then(c => wrapFunctional(c.default || c)),
  ContentForm: () => import('../../components/Content/ContentForm.vue' /* webpackChunkName: "components/content-form" */).then(c => wrapFunctional(c.default || c)),
  ContentGallery: () => import('../../components/Content/ContentGallery.vue' /* webpackChunkName: "components/content-gallery" */).then(c => wrapFunctional(c.default || c)),
  ContentHelper: () => import('../../components/Content/ContentHelper.vue' /* webpackChunkName: "components/content-helper" */).then(c => wrapFunctional(c.default || c)),
  ContentImage: () => import('../../components/Content/ContentImage.vue' /* webpackChunkName: "components/content-image" */).then(c => wrapFunctional(c.default || c)),
  ContentInform: () => import('../../components/Content/ContentInform.vue' /* webpackChunkName: "components/content-inform" */).then(c => wrapFunctional(c.default || c)),
  ContentJavascript: () => import('../../components/Content/ContentJavascript.vue' /* webpackChunkName: "components/content-javascript" */).then(c => wrapFunctional(c.default || c)),
  ContentLinks: () => import('../../components/Content/ContentLinks.vue' /* webpackChunkName: "components/content-links" */).then(c => wrapFunctional(c.default || c)),
  ContentNewsArticles: () => import('../../components/Content/ContentNewsArticles.vue' /* webpackChunkName: "components/content-news-articles" */).then(c => wrapFunctional(c.default || c)),
  ContentPartners: () => import('../../components/Content/ContentPartners.vue' /* webpackChunkName: "components/content-partners" */).then(c => wrapFunctional(c.default || c)),
  ContentQuote: () => import('../../components/Content/ContentQuote.vue' /* webpackChunkName: "components/content-quote" */).then(c => wrapFunctional(c.default || c)),
  ContentReviews: () => import('../../components/Content/ContentReviews.vue' /* webpackChunkName: "components/content-reviews" */).then(c => wrapFunctional(c.default || c)),
  ContentText: () => import('../../components/Content/ContentText.vue' /* webpackChunkName: "components/content-text" */).then(c => wrapFunctional(c.default || c)),
  ContentVideo: () => import('../../components/Content/ContentVideo.vue' /* webpackChunkName: "components/content-video" */).then(c => wrapFunctional(c.default || c)),
  ContentWebinarForm: () => import('../../components/Content/ContentWebinarForm.vue' /* webpackChunkName: "components/content-webinar-form" */).then(c => wrapFunctional(c.default || c)),
  DisclosureHeadless: () => import('../../components/Disclosure/DisclosureHeadless.vue' /* webpackChunkName: "components/disclosure-headless" */).then(c => wrapFunctional(c.default || c)),
  DisclosureItem: () => import('../../components/Disclosure/DisclosureItem.vue' /* webpackChunkName: "components/disclosure-item" */).then(c => wrapFunctional(c.default || c)),
  OfferAllBanks: () => import('../../components/Offer/AllBanks.vue' /* webpackChunkName: "components/offer-all-banks" */).then(c => wrapFunctional(c.default || c)),
  OfferAllBanksBlock: () => import('../../components/Offer/AllBanksBlock.vue' /* webpackChunkName: "components/offer-all-banks-block" */).then(c => wrapFunctional(c.default || c)),
  OfferBestOffersBlock: () => import('../../components/Offer/BestOffersBlock.vue' /* webpackChunkName: "components/offer-best-offers-block" */).then(c => wrapFunctional(c.default || c)),
  OfferChooseSeveralOffersButton: () => import('../../components/Offer/ChooseSeveralOffersButton.vue' /* webpackChunkName: "components/offer-choose-several-offers-button" */).then(c => wrapFunctional(c.default || c)),
  OfferCreditOffer: () => import('../../components/Offer/CreditOffer.vue' /* webpackChunkName: "components/offer-credit-offer" */).then(c => wrapFunctional(c.default || c)),
  OfferCreditOfferHorizontal: () => import('../../components/Offer/CreditOfferHorizontal.vue' /* webpackChunkName: "components/offer-credit-offer-horizontal" */).then(c => wrapFunctional(c.default || c)),
  OfferCreditOffersCatalog: () => import('../../components/Offer/CreditOffersCatalog.vue' /* webpackChunkName: "components/offer-credit-offers-catalog" */).then(c => wrapFunctional(c.default || c)),
  OfferCardDetail: () => import('../../components/Offer/OfferCardDetail.vue' /* webpackChunkName: "components/offer-card-detail" */).then(c => wrapFunctional(c.default || c)),
  OfferReview: () => import('../../components/Offer/Review.vue' /* webpackChunkName: "components/offer-review" */).then(c => wrapFunctional(c.default || c)),
  OfferReviewsBlock: () => import('../../components/Offer/ReviewsBlock.vue' /* webpackChunkName: "components/offer-reviews-block" */).then(c => wrapFunctional(c.default || c)),
  OfferSearchPagesCatalog: () => import('../../components/Offer/SearchPagesCatalog.vue' /* webpackChunkName: "components/offer-search-pages-catalog" */).then(c => wrapFunctional(c.default || c)),
  FormBaseField: () => import('../../components/form/BaseField.vue' /* webpackChunkName: "components/form-base-field" */).then(c => wrapFunctional(c.default || c)),
  FormBaseSelect: () => import('../../components/form/BaseSelect.vue' /* webpackChunkName: "components/form-base-select" */).then(c => wrapFunctional(c.default || c)),
  FormBaseToggle: () => import('../../components/form/BaseToggle.vue' /* webpackChunkName: "components/form-base-toggle" */).then(c => wrapFunctional(c.default || c)),
  FormCalendarField: () => import('../../components/form/CalendarField.vue' /* webpackChunkName: "components/form-calendar-field" */).then(c => wrapFunctional(c.default || c)),
  FormDynamicForm: () => import('../../components/form/DynamicForm.vue' /* webpackChunkName: "components/form-dynamic-form" */).then(c => wrapFunctional(c.default || c)),
  FormDynamicFormFields: () => import('../../components/form/DynamicFormFields.vue' /* webpackChunkName: "components/form-dynamic-form-fields" */).then(c => wrapFunctional(c.default || c)),
  FormFileInput: () => import('../../components/form/FileInput.vue' /* webpackChunkName: "components/form-file-input" */).then(c => wrapFunctional(c.default || c)),
  FormFileInputAtomic: () => import('../../components/form/FileInputAtomic.vue' /* webpackChunkName: "components/form-file-input-atomic" */).then(c => wrapFunctional(c.default || c)),
  FormInputField: () => import('../../components/form/InputField.vue' /* webpackChunkName: "components/form-input-field" */).then(c => wrapFunctional(c.default || c)),
  FormRatingInput: () => import('../../components/form/RatingInput.vue' /* webpackChunkName: "components/form-rating-input" */).then(c => wrapFunctional(c.default || c)),
  FormSelectField: () => import('../../components/form/SelectField.vue' /* webpackChunkName: "components/form-select-field" */).then(c => wrapFunctional(c.default || c)),
  FormSelectFieldModal: () => import('../../components/form/SelectFieldModal.vue' /* webpackChunkName: "components/form-select-field-modal" */).then(c => wrapFunctional(c.default || c)),
  FormSliderInput: () => import('../../components/form/SliderInput.vue' /* webpackChunkName: "components/form-slider-input" */).then(c => wrapFunctional(c.default || c)),
  FormToggleGroup: () => import('../../components/form/ToggleGroup.vue' /* webpackChunkName: "components/form-toggle-group" */).then(c => wrapFunctional(c.default || c)),
  SwiperAllBanksSwiper: () => import('../../components/swiper/AllBanksSwiper.vue' /* webpackChunkName: "components/swiper-all-banks-swiper" */).then(c => wrapFunctional(c.default || c)),
  SwiperBaseSwiper: () => import('../../components/swiper/BaseSwiper.vue' /* webpackChunkName: "components/swiper-base-swiper" */).then(c => wrapFunctional(c.default || c)),
  SwiperBestOffersSwiper: () => import('../../components/swiper/BestOffersSwiper.vue' /* webpackChunkName: "components/swiper-best-offers-swiper" */).then(c => wrapFunctional(c.default || c)),
  SwiperPartnersSwiper: () => import('../../components/swiper/PartnersSwiper.vue' /* webpackChunkName: "components/swiper-partners-swiper" */).then(c => wrapFunctional(c.default || c)),
  SwiperPromoOffersSwiper: () => import('../../components/swiper/PromoOffersSwiper.vue' /* webpackChunkName: "components/swiper-promo-offers-swiper" */).then(c => wrapFunctional(c.default || c)),
  SwiperReviewsSwiper: () => import('../../components/swiper/ReviewsSwiper.vue' /* webpackChunkName: "components/swiper-reviews-swiper" */).then(c => wrapFunctional(c.default || c)),
  SwiperCards: () => import('../../components/swiper/SwiperCards.vue' /* webpackChunkName: "components/swiper-cards" */).then(c => wrapFunctional(c.default || c)),
  SwiperThumbs: () => import('../../components/swiper/SwiperThumbs.vue' /* webpackChunkName: "components/swiper-thumbs" */).then(c => wrapFunctional(c.default || c)),
  OfferMarkupCardRemovable: () => import('../../components/Offer/markup/CardRemovable.vue' /* webpackChunkName: "components/offer-markup-card-removable" */).then(c => wrapFunctional(c.default || c)),
  OfferCard: () => import('../../components/Offer/markup/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c)),
  OfferCardHorizontal: () => import('../../components/Offer/markup/OfferCardHorizontal.vue' /* webpackChunkName: "components/offer-card-horizontal" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
